@use "sass:map";
@use "dimensions" as *;

// to be removed ↓
$z-index-map: (
  "01": 10,
  "02": 20,
  "03": 30,
  "04": 40,
  "05": 50,
  "99": 999999,
);

// to be removed ↑

/* ----------------------***-------------------------- 
 ________ Elevation 
 -------------------------***-------------------------- */

// ------------- Shadow References -------------
$ref-shadow-ambient-color: hsla(0deg 0% 0% / 15%);
$ref-shadow-ambient-level0-blur: 0;
$ref-shadow-ambient-level0-spread: 0;
$ref-shadow-ambient-level0-x: 0;
$ref-shadow-ambient-level0-y: 0;
$ref-shadow-ambient-level1-blur: size(3);
$ref-shadow-ambient-level1-spread: size(1);
$ref-shadow-ambient-level1-x: 0;
$ref-shadow-ambient-level1-y: size(1);
$ref-shadow-ambient-level2-blur: size(6);
$ref-shadow-ambient-level2-spread: size(2);
$ref-shadow-ambient-level2-x: 0;
$ref-shadow-ambient-level2-y: size(2);
$ref-shadow-ambient-level3-blur: size(8);
$ref-shadow-ambient-level3-spread: size(3);
$ref-shadow-ambient-level3-x: 0;
$ref-shadow-ambient-level3-y: size(4);
$ref-shadow-ambient-level4-blur: size(10);
$ref-shadow-ambient-level4-spread: size(4);
$ref-shadow-ambient-level4-x: 0;
$ref-shadow-ambient-level4-y: size(6);
$ref-shadow-ambient-level5-blur: size(12);
$ref-shadow-ambient-level5-spread: size(6);
$ref-shadow-ambient-level5-x: 0;
$ref-shadow-ambient-level5-y: size(8);
$ref-shadow-key-color: hsla(0deg 0% 0% / 30%);
$ref-shadow-key-level0-blur: 0;
$ref-shadow-key-level0-spread: 0;
$ref-shadow-key-level0-x: 0;
$ref-shadow-key-level0-y: 0;
$ref-shadow-key-level1-blur: size(2);
$ref-shadow-key-level1-spread: 0;
$ref-shadow-key-level1-x: 0;
$ref-shadow-key-level1-y: size(1);
$ref-shadow-key-level2-blur: size(2);
$ref-shadow-key-level2-spread: 0;
$ref-shadow-key-level2-x: 0;
$ref-shadow-key-level2-y: size(1);
$ref-shadow-key-level3-blur: size(2);
$ref-shadow-key-level3-spread: 0;
$ref-shadow-key-level3-x: 0;
$ref-shadow-key-level3-y: size(1);
$ref-shadow-key-level4-blur: size(3);
$ref-shadow-key-level4-spread: 0;
$ref-shadow-key-level4-x: 0;
$ref-shadow-key-level4-y: size(1);
$ref-shadow-key-level5-blur: size(4);
$ref-shadow-key-level5-spread: 0;
$ref-shadow-key-level5-x: 0;
$ref-shadow-key-level5-y: size(4);

// ------------- Elevation Indexes -------------

$sys-elevation-level0: 0;
$sys-elevation-level1: 1;
$sys-elevation-level2: 3;
$sys-elevation-level3: 6;
$sys-elevation-level4: 8;
$sys-elevation-level5: 12;

// ------------- Ambient Shadow Variables -------------

$ref-shadow-ambient-level-0: #{$ref-shadow-ambient-level0-x}
  #{$ref-shadow-ambient-level0-y} #{$ref-shadow-ambient-level0-blur}
  #{$ref-shadow-ambient-level0-spread} #{$ref-shadow-ambient-color};
$ref-shadow-ambient-level-1: #{$ref-shadow-ambient-level1-x}
  #{$ref-shadow-ambient-level1-y} #{$ref-shadow-ambient-level1-blur}
  #{$ref-shadow-ambient-level1-spread} #{$ref-shadow-ambient-color};
$ref-shadow-ambient-level-2: #{$ref-shadow-ambient-level2-x}
  #{$ref-shadow-ambient-level2-y} #{$ref-shadow-ambient-level2-blur}
  #{$ref-shadow-ambient-level2-spread} #{$ref-shadow-ambient-color};
$ref-shadow-ambient-level-3: #{$ref-shadow-ambient-level3-x}
  #{$ref-shadow-ambient-level3-y} #{$ref-shadow-ambient-level3-blur}
  #{$ref-shadow-ambient-level3-spread} #{$ref-shadow-ambient-color};
$ref-shadow-ambient-level-4: #{$ref-shadow-ambient-level4-x}
  #{$ref-shadow-ambient-level4-y} #{$ref-shadow-ambient-level4-blur}
  #{$ref-shadow-ambient-level4-spread} #{$ref-shadow-ambient-color};
$ref-shadow-ambient-level-5: #{$ref-shadow-ambient-level5-x}
  #{$ref-shadow-ambient-level5-y} #{$ref-shadow-ambient-level5-blur}
  #{$ref-shadow-ambient-level5-spread} #{$ref-shadow-ambient-color};

// ------------- Key Shadow Variables -------------

$ref-shadow-key-level-0: #{$ref-shadow-key-level0-x} #{$ref-shadow-key-level0-y}
  #{$ref-shadow-key-level0-blur} #{$ref-shadow-key-level0-spread}
  #{$ref-shadow-key-color};
$ref-shadow-key-level-1: #{$ref-shadow-key-level1-x} #{$ref-shadow-key-level1-y}
  #{$ref-shadow-key-level1-blur} #{$ref-shadow-key-level1-spread}
  #{$ref-shadow-key-color};
$ref-shadow-key-level-2: #{$ref-shadow-key-level2-x} #{$ref-shadow-key-level2-y}
  #{$ref-shadow-key-level2-blur} #{$ref-shadow-key-level2-spread}
  #{$ref-shadow-key-color};
$ref-shadow-key-level-3: #{$ref-shadow-key-level3-x} #{$ref-shadow-key-level3-y}
  #{$ref-shadow-key-level3-blur} #{$ref-shadow-key-level3-spread}
  #{$ref-shadow-key-color};
$ref-shadow-key-level-4: #{$ref-shadow-key-level4-x} #{$ref-shadow-key-level4-y}
  #{$ref-shadow-key-level4-blur} #{$ref-shadow-key-level4-spread}
  #{$ref-shadow-key-color};
$ref-shadow-key-level-5: #{$ref-shadow-key-level5-x} #{$ref-shadow-key-level5-y}
  #{$ref-shadow-key-level5-blur} #{$ref-shadow-key-level5-spread}
  #{$ref-shadow-key-color};
