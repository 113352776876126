@use "../01-abstracts" as *;

// Colors
.bg--surface-container {
  background-color: $sys-color-surface_container;
}

.bg--surface-container-low {
  background-color: $sys-color-surface_container_low;
}

.bg--background {
  background-color: $sys-color-background;
}

.bg--alternative {
  background-color: $sys-color-alternative;
}

.bg--alternative-container {
  background-color: $sys-color-alternative_container;
}

.bg--alternative-fixed {
  background-color: $sys-color-alternative_fixed;
}

.bg--alternative-fixed-dim {
  background-color: $sys-color-alternative_fixed_dim;
}

.bg--error {
  background-color: $sys-color-error;
}

.bg--error-container {
  background-color: $sys-color-error_container;
}

.bg--inverse-on-surface {
  background-color: $sys-color-inverse_on_surface;
}

.bg--inverse-primary {
  background-color: $sys-color-inverse_primary;
}

.bg--inverse-surface {
  background-color: $sys-color-inverse_surface;
}

.bg--outline {
  background-color: $sys-color-outline;
}

.bg--outline-variant {
  background-color: $sys-color-outline_variant;
}

.bg--primary {
  background-color: $sys-color-primary;
}

.bg--primary-container {
  background-color: $sys-color-primary_container;
}

.bg--primary-fixed {
  background-color: $sys-color-primary_fixed;
}

.bg--primary-fixed-dim {
  background-color: $sys-color-primary_fixed_dim;
}

.bg--scrim {
  background-color: $sys-color-scrim;
}

.bg--secondary {
  background-color: $sys-color-secondary;
}

.bg--secondary-container {
  background-color: $sys-color-secondary_container;
}

.bg--secondary-fixed {
  background-color: $sys-color-secondary_fixed;
}

.bg--secondary-fixed-dim {
  background-color: $sys-color-secondary_fixed_dim;
}

.bg--shadow {
  background-color: $sys-color-shadow;
}

.bg--surface {
  background-color: $sys-color-surface;
}

.bg--surface-bright {
  background-color: $sys-color-surface_bright;
}

.bg--surface-container-high {
  background-color: $sys-color-surface_container_high;
}

.bg--surface-container-highest {
  background-color: $sys-color-surface_container_highest;
}

.bg--surface-container-lowest {
  background-color: $sys-color-surface_container_lowest;
}

.bg--surface-dim {
  background-color: $sys-color-surface_dim;
}

.bg--tertiary {
  background-color: $sys-color-tertiary;
}

.bg--tertiary-container {
  background-color: $sys-color-tertiary_container;
}

.bg--tertiary-fixed {
  background-color: $sys-color-tertiary_fixed;
}

.bg--tertiary-fixed-dim {
  background-color: $sys-color-tertiary_fixed_dim;
}

// Custom
.bg--blur {
  background-color: hsl(from $sys-color-on-surface h s l / 40%);
  backdrop-filter: blur(spacing("px"));
}
