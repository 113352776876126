@use "../01-abstracts" as *;

.nax-product-select-input {
  display: flex;
  flex-direction: row;
  gap: size(2);
  height: inherit;
  padding: size(4) size(8);
  pointer-events: all;
  background-color: var(--background-color-low-emphasis);
  border-radius: size(20);

  .date-filtered {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--color-primary-invert);
    background-color: var(--color-primary-brand);
    border-radius: var(--border-radius);
  }
}
