@use "../01-abstracts" as *;

// TODOSTATES
.nax-product-card {
  &__button {
    @include user-action-states(currentcolor, $sys-color-surface);
  }

  .product--active {
    @include state-color-mix(currentcolor, $sys-color-surface, $state-active);
  }
}
