@use "../01-abstracts" as *;
@use "../02-tools" as *;

// 86c0czrh2 Replace when text shadow token is defined
$text-shadow: #{$ref-shadow-key-level3-x} #{$ref-shadow-key-level3-y}
  #{$ref-shadow-key-level3-blur} #{$ref-shadow-key-color};

.nax-legend {
  &__container {
    @extend %elevation-level3;
    @include rounded--large;

    padding: spacing(1);
    background-color: $sys-color-surface-container-highest;

    @include for-orientation("portrait") {
      display: flex;
      gap: spacing(2);
    }
  }

  &__data-container {
    @extend %scrollbar-hidden;
    @include rounded--large;

    display: flex;
    flex-grow: 1;
    gap: spacing(1);
    padding-block: spacing(1);
    overflow-x: auto;

    @include for-orientation("landscape") {
      flex-direction: column-reverse;
    }
  }

  &__tag {
    padding-block: spacing(1);
    padding-inline: spacing("1_5");
    text-wrap: nowrap;
    text-shadow: $text-shadow;
  }

  &__cloud-tag {
    --cloud-color: white;

    padding: spacing(1) spacing("0_5");

    &::after {
      @include rounded--full;

      display: inline-block;
      width: spacing("3_5");
      height: spacing("3_5");
      content: "";
      background-color: var(--cloud-color);
      border: spacing(px) solid $sys-color-outline_variant;
    }
  }

  &__gradient-bar {
    @include rounded--large;

    flex-grow: 1;
    height: spacing(3);
    margin: auto;

    @include for-orientation("landscape") {
      flex-direction: column;
      width: spacing(3);
      height: spacing(52);
    }
  }
}
