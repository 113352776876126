@use "../01-abstracts" as *;
@use "../02-tools" as *;

.grid {
  display: grid;
}

.grid-center {
  display: grid;
  place-items: center;
}
