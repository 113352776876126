@use "../01-abstracts" as *;
@use "../02-tools" as *;

.border {
  border: spacing(px) solid $sys-color-outline;
}

.border-left {
  border-left: spacing(px) solid var(--sys-color-outline_variant);
}

.border-top {
  border-top: spacing(px) solid var(--sys-color-outline_variant);
}

.border--variant {
  border: spacing(px) solid $sys-color-outline_variant;
}

.rounded--extra-large {
  @include rounded--extra-large;
}

.rounded--extra-small {
  @include rounded--extra-small;
}

.rounded--full {
  @include rounded--full;
}

.rounded--large {
  @include rounded--large;
}

.rounded--medium {
  @include rounded--medium;
}

.rounded--none {
  @include rounded--none;
}

.rounded--small {
  @include rounded--small;
}
