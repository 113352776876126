@use "../../01-abstracts" as *;
@use "./prime-global" as *;

.p-textarea {
  --p-textarea-focus-border-color: #{$sys-color-primary};

  width: 100%;
  min-height: spacing(12);
  resize: vertical;

  @extend %prime-input-common;
}

.nax-textarea {
  width: 100%;
}
