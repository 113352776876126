@use "../01-abstracts" as *;

// Why not just display block?
.nax-authentication {
  display: grid;
  grid-template: "main" 1fr / 1fr;
  height: 100dvh;

  .nax-login,
  .nax-remember-password,
  .nax-reset-password {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include for-orientation("portrait") {
      flex-direction: column;
    }
  }
}
