@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-dialog {
  &__backdrop {
    position: fixed;
    inset: 0;
    border: spacing(2) solid transparent;
  }

  &__dialog {
    @extend %elevation-level3;
    @include rounded--extra-large;

    min-width: spacing(72);
    max-width: spacing(150);
    color: $sys-color-on-surface;
    background-color: $sys-color-surface-container-high;
  }

  &__subtitle:empty {
    display: none;
  }
}
