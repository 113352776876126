@forward "table";
@forward "select";
@forward "calendar";
@forward "multiselect";
@forward "switch";
@forward "input-date";
@forward "input-number";
@forward "input-text";
@forward "input-textarea";
@forward "checkbox";
@forward "tiered-menu";
@forward "radio-button";
@forward "progress-bar";

// -------
@forward "prime-global";
@layer primeng, primengreset;
