@use "../01-abstracts" as *;

.app-root {
  display: flex;
  height: 100%;
  overflow: hidden;

  @include for-orientation("portrait") {
    flex-direction: column-reverse;
  }
}
