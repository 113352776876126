@use "../02-tools" as *;

/* stylelint-disable property-disallowed-list */

.body--large {
  @include body--large;
}

.body--medium {
  @include body--medium;
}

.body--small {
  @include body--small;
}

.display--large {
  @include display--large;
}

.display--medium {
  @include display--medium;
}

.display--small {
  @include display--small;
}

.headline--large {
  @include headline--large;
}

.headline--medium {
  @include headline--medium;
}

.headline--small {
  @include headline--small;
}

.label--large {
  @include label--large;
}

.label--medium {
  @include label--medium;
}

.label--small {
  @include label--small;
}

.title--large {
  @include title--large;
}

.title--medium {
  @include title--medium;
}

.title--small {
  @include title--small;
}
