/* stylelint-disable scss/at-mixin-pattern */
/* stylelint-disable property-disallowed-list */

@use "../01-abstracts" as *;

@mixin body--large {
  font-size: size($sys-typescale-body-large-size);
  font-weight: $sys-typescale-body-large-weight;
  line-height: size($sys-typescale-body-large-line_height);
  letter-spacing: size($sys-typescale-body-large-letter_spacing);
}

@mixin body--medium {
  font-size: size($sys-typescale-body-medium-size);
  font-weight: $sys-typescale-body-medium-weight;
  line-height: size($sys-typescale-body-medium-line_height);
  letter-spacing: size($sys-typescale-body-medium-letter_spacing);
}

@mixin body--small {
  font-size: size($sys-typescale-body-small-size);
  font-weight: $sys-typescale-body-small-weight;
  line-height: size($sys-typescale-body-small-line_height);
  letter-spacing: size($sys-typescale-body-small-letter_spacing);
}

@mixin display--large {
  font-size: size($sys-typescale-display-large-size);
  font-weight: $sys-typescale-display-large-weight;
  line-height: size($sys-typescale-display-large-line_height);
  letter-spacing: size($sys-typescale-display-large-letter_spacing);
}

@mixin display--medium {
  font-size: size($sys-typescale-display-medium-size);
  font-weight: $sys-typescale-display-medium-weight;
  line-height: size($sys-typescale-display-medium-line_height);
}

@mixin display--small {
  font-size: size($sys-typescale-display-small-size);
  font-weight: $sys-typescale-display-small-weight;
  line-height: size($sys-typescale-display-small-line_height);
}

@mixin headline--large {
  font-size: size($sys-typescale-headline-large-size);
  font-weight: $sys-typescale-headline-large-weight;
  line-height: size($sys-typescale-headline-large-line_height);
}

@mixin headline--medium {
  font-size: size($sys-typescale-headline-medium-size);
  font-weight: $sys-typescale-headline-medium-weight;
  line-height: size($sys-typescale-headline-medium-line_height);
}

@mixin headline--small {
  font-size: size($sys-typescale-headline-small-size);
  font-weight: $sys-typescale-headline-small-weight;
  line-height: size($sys-typescale-headline-small-line_height);
}

@mixin label--large {
  font-size: size($sys-typescale-label-large-size);
  font-weight: $sys-typescale-label-large-weight;
  line-height: size($sys-typescale-label-large-line_height);
  letter-spacing: size($sys-typescale-label-large-letter_spacing);
}

@mixin label--medium {
  font-size: size($sys-typescale-label-medium-size);
  font-weight: $sys-typescale-label-medium-weight;
  line-height: size($sys-typescale-label-medium-line_height);
  letter-spacing: size($sys-typescale-label-medium-letter_spacing);
}

@mixin label--small {
  font-size: size($sys-typescale-label-small-size);
  font-weight: $sys-typescale-label-small-weight;
  line-height: size($sys-typescale-label-small-line_height);
  letter-spacing: size($sys-typescale-label-small-letter_spacing);
}

@mixin title--large {
  font-size: size($sys-typescale-title-large-size);
  font-weight: $sys-typescale-title-large-weight;
  line-height: size($sys-typescale-title-large-line_height);
}

@mixin title--medium {
  font-size: size($sys-typescale-title-medium-size);
  font-weight: $sys-typescale-title-medium-weight;
  line-height: size($sys-typescale-title-medium-line_height);
  letter-spacing: size($sys-typescale-title-medium-letter_spacing);
}

@mixin title--small {
  font-size: size($sys-typescale-title-small-size);
  font-weight: $sys-typescale-title-small-weight;
  line-height: size($sys-typescale-title-small-line_height);
  letter-spacing: size($sys-typescale-title-small-letter_spacing);
}
