@use "../../01-abstracts" as *;

p-radio-button {
  --p-radiobutton-checked-border-color: #{$sys-color-primary};
  --p-radiobutton-checked-background: #{$sys-color-primary};
  --p-radiobutton-border-color: #{$sys-color-primary};
  --p-radiobutton-icon-checked-color: #{$sys-color-primary};

  .p-radiobutton {
    width: spacing(4);
    height: spacing(4);

    &-box {
      width: spacing(4);
      height: spacing(4);
    }

    &-checked {
      .p-radiobutton-icon {
        border: spacing("0_5") solid $sys-color-surface;
      }
    }
  }
}
