@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-map-search {
  &__suggestion-item {
    @include user-action-states(
      $sys-color-on_secondary_container,
      $sys-color-surface_container
    ) {
      border-color: $sys-color-outline_variant;
    }
  }
}
