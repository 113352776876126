@use "../01-abstracts" as *;

%scrollbar-hidden {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @include for-browser("firefox") {
    scrollbar-width: 0;
  }
}
