.nax-breadcrumb {
  display: flex;
  align-items: center;

  &__directory {
    color: var(--sys-color-on_surface_variant);

    &--active {
      color: var(--sys-color-on_background);
    }

    &:not(:last-child)::after {
      margin: 0 0.5ch;
      content: "/";
    }
  }
}
