@use "../01-abstracts" as *;

.nax-home {
  display: grid;
  grid-template: 1fr auto / 1fr;
  gap: spacing(10);
  padding: spacing(4);

  @include for-orientation("landscape") {
    gap: spacing(16);
    padding: spacing(10);
  }
}
