/* ----------------------***-------------------------- 
 ________ Typescale 
 -------------------------***-------------------------- */
$native-font-stack:
  sans-serif,
  system-ui,
  -apple-system,
  "Segoe UI",
  roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  arial,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
$font-base:
  Inter,
  #{$native-font-stack};

// ------------- Typescale Ref Variables -------------
$sys-typescale-body-large-letter_spacing: 0.5;
$sys-typescale-body-large-line_height: 24;
$sys-typescale-body-large-size: 16;
$sys-typescale-body-large-weight: 400;
$sys-typescale-body-medium-letter_spacing: 0.25;
$sys-typescale-body-medium-line_height: 20;
$sys-typescale-body-medium-size: 14;
$sys-typescale-body-medium-weight: 400;
$sys-typescale-body-small-letter_spacing: 0.4;
$sys-typescale-body-small-line_height: 16;
$sys-typescale-body-small-size: 12;
$sys-typescale-body-small-weight: 400;
$sys-typescale-display-large-letter_spacing: -0.25;
$sys-typescale-display-large-line_height: 64;
$sys-typescale-display-large-size: 57;
$sys-typescale-display-large-weight: 400;
$sys-typescale-display-medium-line_height: 52;
$sys-typescale-display-medium-size: 45;
$sys-typescale-display-medium-weight: 400;
$sys-typescale-display-small-line_height: 44;
$sys-typescale-display-small-size: 36;
$sys-typescale-display-small-weight: 400;
$sys-typescale-headline-large-line_height: 40;
$sys-typescale-headline-large-size: 32;
$sys-typescale-headline-large-weight: 400;
$sys-typescale-headline-medium-line_height: 36;
$sys-typescale-headline-medium-size: 28;
$sys-typescale-headline-medium-weight: 400;
$sys-typescale-headline-small-line_height: 32;
$sys-typescale-headline-small-size: 24;
$sys-typescale-headline-small-weight: 400;
$sys-typescale-label-large-letter_spacing: 0.1;
$sys-typescale-label-large-line_height: 20;
$sys-typescale-label-large-size: 14;
$sys-typescale-label-large-weight: 500;
$sys-typescale-label-medium-letter_spacing: 0.5;
$sys-typescale-label-medium-line_height: 16;
$sys-typescale-label-medium-size: 12;
$sys-typescale-label-medium-weight: 500;
$sys-typescale-label-small-letter_spacing: 0.5;
$sys-typescale-label-small-line_height: 16;
$sys-typescale-label-small-size: 10;
$sys-typescale-label-small-weight: 500;
$sys-typescale-title-large-line_height: 28;
$sys-typescale-title-large-size: 22;
$sys-typescale-title-large-weight: 400;
$sys-typescale-title-medium-letter_spacing: 0.15;
$sys-typescale-title-medium-line_height: 24;
$sys-typescale-title-medium-size: 16;
$sys-typescale-title-medium-weight: 500;
$sys-typescale-title-small-letter_spacing: 0.1;
$sys-typescale-title-small-line_height: 20;
$sys-typescale-title-small-size: 14;
$sys-typescale-title-small-weight: 400;
