meter {
  min-height: size(24);

  &::-webkit-meter-bar {
    background: var(--background-color-high-emphasis);
  }

  &::-webkit-meter-even-less-good-value {
    background: var(--color-error);
  }

  &::-webkit-meter-suboptimum-value {
    background: var(--color-warning);
  }

  &::-webkit-meter-optimum-value {
    background: var(--color-primary-brand);
  }
}
