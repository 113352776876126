@use "../01-abstracts" as *;
@use "../02-tools" as *;

article {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  min-height: 0;

  // Scenario 1. Article with a stepper
  .nax-stepper {
    flex-grow: 1;
    min-height: 0;
  }

  // Scenario 2. Article without a stepper
  // Articles main content selector
  &:not(:has(.nax-stepper)) .nax-article-header + *,
  &:not(:has(.nax-stepper)) .nax-article-header + router-outlet + * {
    flex-grow: 1;
    min-height: 0;
    padding: spacing(4);
    overflow: auto;
  }

  // Behavior applicable to all scenarios
  footer {
    display: flex;
    gap: spacing(3);
    align-items: center;
    justify-content: flex-end;
    padding: spacing(3);
    margin-inline: spacing(4);

    @include for-breakpoint("s") {
      padding-block-end: spacing(7);
    }
  }

  // ! Follows same pattern as the stepper footer
  .max-w-screen-m + footer,
  .max-w-screen-m--children + footer {
    @extend %max-w-screen-m;
  }

  .max-w-screen-l + footer,
  .max-w-screen-l--children + footer {
    @extend %max-w-screen-l;
  }
}
