/* stylelint-disable unit-allowed-list */

/* ================================================================== */

/* Drawing styles
/* ================================================================== */

.leaflet-mouse-marker {
  cursor: crosshair;
  background-color: #fff;
}

.leaflet-draw-tooltip {
  position: absolute;
  z-index: 6;
  visibility: hidden;
  padding: 4px 8px;
  margin-top: -21px;
  margin-left: 20px;
  color: #fff;
  white-space: nowrap;
  background: rgb(54 54 54);
  background: rgb(0 0 0 / 50%);
  border: 1px solid transparent;
  border-radius: 4px;
}

.leaflet-draw-tooltip::before {
  position: absolute;
  top: 7px;
  left: -7px;
  content: "";
  border-top: 6px solid transparent;
  border-right: 6px solid black;
  border-right-color: rgb(0 0 0 / 50%);
  border-bottom: 6px solid transparent;
}

.leaflet-error-draw-tooltip {
  color: #b94a48;
  background-color: #f2dede;
  border: 1px solid #e6b6bd;

  &.leaflet-tooltip-left::before {
    border-left-color: #e6b6bd;
  }

  &.leaflet-tooltip-right::before {
    border-right-color: #e6b6bd;
  }
}

.leaflet-draw-tooltip-single {
  margin-top: -12px;
}

.leaflet-draw-tooltip-subtext {
  color: #f8d5e4;
}

.leaflet-draw-guide-dash {
  position: absolute;
  width: 5px;
  height: 5px;
  font-size: 1%;
  opacity: 0.6;
}

/* ================================================================== */

/* Edit styles
/* ================================================================== */

.leaflet-edit-marker-selected {
  box-sizing: content-box;
  background-color: rgb(254 87 161 / 10%);
  border: 4px solid rgb(254 87 161 / 60%);
  border-radius: 4px;
}

.leaflet-edit-move {
  cursor: move;
}

.leaflet-edit-resize {
  cursor: pointer;
}

/* ================================================================== */

/* Old IE styles
/* ================================================================== */

.leaflet-oldie .leaflet-draw-toolbar {
  border: 1px solid #999;
}

.leaflet-editing-icon {
  border-radius: 99px;
}
