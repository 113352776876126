@use "../../01-abstracts" as *;
@use "../../02-tools" as *;
@use "../../06-objects" as *;
@use "./prime-global" as *;

p-select {
  .p-select-dropdown {
    --p-select-dropdown-color: $sys-color-on_surface;

    width: max-content;

    .p-select-dropdown-icon {
      display: grid;
      color: $sys-color-on-surface;
    }
  }

  &.p-icon-button .p-select-dropdown {
    width: spacing(10);
    height: spacing(10);
  }

  .p-select-label {
    padding: 0;
  }

  .p-select-clear-icon {
    position: relative;
    inset: unset;
  }

  &.p-select:not(.p-disabled).p-focus {
    border-color: $sys-color-outline_variant;
    box-shadow: none;
  }

  .p-select-filter {
    padding-right: spacing(8);
  }

  .p-inputtext {
    @include label--large;

    padding: 0;
  }

  &.p-select {
    width: 100%;
    height: spacing(12);
    padding: 0 spacing(4);
    text-overflow: ellipsis;
    background-color: $sys-color-surface_container;
    border-color: $sys-color-outline-variant;

    span {
      padding-block: 0;
      margin-block: auto;
    }

    @include user-action-states(
      $sys-color-on_secondary_container,
      var(--sys-color-surface_container)
    ) {
      border-color: $sys-color-outline_variant;
    }

    .p-select-label:not(.p-placeholder) {
      color: $sys-color-on_secondary_container;
    }

    .p-overlay {
      max-width: min(100%, spacing(110));
    }
  }

  &.rounded--full.p-select {
    @include rounded--full;
  }

  &.p-icon-button {
    &.p-select {
      height: unset;
      padding: 0;
      margin: 0;

      &-trigger {
        width: spacing(10);
        height: spacing(10);

        &-icon {
          margin: 0;
          color: $sys-color-on-surface;
        }
      }

      .p-select-label {
        display: none;
      }
    }
  }

  &.size--small {
    &.p-select {
      @include body--medium;

      // height: spacing(8);
      height: unset;
      padding: 0;
      color: $sys-color-on-surface;
      outline: spacing("px") solid $sys-color-outline;
      outline-offset: -(spacing("px"));
      border: none;
      border-radius: $sys-shape-corner-small;

      .p-select-option,
      .p-select-label,
      .p-inputtext {
        @include body--medium;
      }

      .p-select-label {
        padding-block: spacing("1_5");
        padding-inline: spacing(3) spacing(1);
      }

      .p-select-label:not(.p-placeholder) {
        color: $sys-color-on-surface;
      }

      .p-select-dropdown {
        padding-inline-end: spacing(1);
      }
    }
  }
}

.p-select-overlay {
  padding-block: spacing(2);
  overflow: hidden;
  background-color: $sys-color-surface_container_highest;
  border-radius: $sys-shape-corner-extra_small;
}

.p-select-list-container:not(.p-multiselect-items-wrapper) {
  overflow: auto;

  p-selectitem {
    display: block;
    height: max-content;
    padding-bottom: spacing("1_5");

    @extend %o-hr-after;

    .p-select-option {
      @include label--large;

      padding: spacing("1_5") spacing(3);
      margin-bottom: spacing("1_5");
      color: $sys-color-on_surface;

      @include user-action-states(
        $sys-color-on_surface,
        var(--sys-color-surface_container_highest)
      );

      span {
        text-wrap: wrap;
      }

      &-selected {
        @include state-color-mix(
          $sys-color-on_surface,
          $sys-color-surface_container_highest,
          $state-active
        );
      }
    }
  }

  .p-scroller {
    overflow: auto;
  }

  .p-select-element {
    @extend %truncate;
  }

  .p-scroller-loader {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: spacing(10);
    padding: spacing(2) spacing(3);

    &::after {
      width: min(spacing(32), 40%);
      height: spacing(4);
      content: "";
    }
  }

  .p-scroller-loader:nth-child(even) {
    &::after {
      width: min(spacing(40), 60%);
    }
  }
}
