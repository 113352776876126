@use "../01-abstracts" as *;
@use "../02-tools" as *;

.o-tag,
%o-tag {
  @include label--small;

  display: flex;
  gap: spacing(2);
  align-items: center;
  padding: spacing(1) spacing(2);
  background-color: $sys-color-surface;
  border-radius: $sys-shape-corner-small;
}
