@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-cta {
  --_color: transparent;
  --_bg-color: transparent;
  --_padding: 0;
  --_gap: 0;

  @include rounded--full;

  display: inline-block;
  width: fit-content;
  height: fit-content;
  overflow: clip;
  color: var(--_color);

  // vertical-align: baseline;
  background-color: var(--_bg-color);

  @include user-action-states(var(--_color), var(--_bg-color));

  &__a,
  &__button {
    display: flex;
    gap: var(--_gap);
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: var(--_padding);
  }

  // Sizes - Button
  &--xl {
    --_padding: #{spacing(3) spacing(6)};
    --_gap: #{spacing(2)};

    @include label--large;
  }

  &--lg {
    --_padding: #{spacing("2_5") spacing("3_5")};
    --_gap: #{spacing(2)};

    @include label--large;
  }

  &--md {
    --_padding: #{spacing(2) spacing(3)};
    --_gap: #{spacing("1_5")};

    @include label--medium;
  }

  &--sm {
    --_padding: 0 #{spacing(2)};
    --_gap: #{spacing(1)};

    @include label--medium;
  }

  // Sizes - Icon Button
  &--icon-2xl {
    --_padding: #{spacing(3)};
  }

  &--icon-xl {
    --_padding: #{spacing(2)};
  }

  &--icon-lg {
    --_padding: #{spacing(2)};
  }

  &--icon-md {
    --_padding: #{spacing("1_5")};
  }

  &--icon-sm {
    --_padding: #{spacing(1)};
  }

  // Types
  &--primary {
    &-filled {
      --_color: #{$sys-color-on-primary};
      --_bg-color: #{$sys-color-primary};
    }

    &-outline {
      --_color: #{$sys-color-primary};
      --_bg-color: #{$sys-color-surface};

      outline: spacing(px) solid $sys-color-outline_variant;
    }

    &-text {
      --_color: #{$sys-color-primary};
      --_bg-color: transparent;
    }

    &-link {
      --_color: #{$sys-color-primary};
      --_bg-color: transparent;
      --_padding: 0 #{spacing(1)};
    }

    &-tonal {
      --_color: #{$sys-color-on-primary-container};
      --_bg-color: #{$sys-color-primary-container};
    }

    &-elevated {
      @extend %elevation-level1;

      --_color: #{$sys-color-primary};
      --_bg-color: #{$sys-color-surface};
    }
  }

  &--secondary {
    &-filled {
      --_color: #{$sys-color-on-secondary};
      --_bg-color: #{$sys-color-secondary};
    }

    &-outline {
      --_color: #{$sys-color-secondary};
      --_bg-color: #{$sys-color-surface};

      outline: spacing(px) solid $sys-color-outline_variant;
    }

    &-text {
      --_color: #{$sys-color-secondary};
      --_bg-color: transparent;
    }

    &-link {
      --_color: #{$sys-color-secondary};
      --_bg-color: transparent;
      --_padding: 0 #{spacing(1)};
    }

    &-tonal {
      --_color: #{$sys-color-on-secondary-container};
      --_bg-color: #{$sys-color-secondary-container};
    }

    &-elevated {
      @extend %elevation-level1;

      --_color: #{$sys-color-secondary};
      --_bg-color: #{$sys-color-surface};
    }
  }

  &--success {
    &-filled {
      --_color: #{$sys-color-on-tertiary};
      --_bg-color: #{$sys-color-tertiary};
    }

    &-outline {
      --_color: #{$sys-color-tertiary};
      --_bg-color: #{$sys-color-surface};

      outline: spacing(px) solid $sys-color-outline_variant;
    }

    &-text {
      --_color: #{$sys-color-tertiary};
      --_bg-color: transparent;
    }

    &-link {
      --_color: #{$sys-color-tertiary};
      --_bg-color: transparent;
      --_padding: 0 #{spacing(1)};
    }

    &-tonal {
      --_color: #{$sys-color-on-tertiary-container};
      --_bg-color: #{$sys-color-tertiary-container};
    }

    &-elevated {
      @extend %elevation-level1;

      --_color: #{$sys-color-on-tertiary};
      --_bg-color: #{$sys-color-tertiary};
    }
  }

  &--error {
    &-filled {
      --_color: #{$sys-color-on-error};
      --_bg-color: #{$sys-color-error};
    }

    &-outline {
      --_color: #{$sys-color-error};
      --_bg-color: #{$sys-color-surface};

      outline: spacing(px) solid $sys-color-outline_variant;
    }

    &-text {
      --_color: #{$sys-color-error};
      --_bg-color: transparent;
    }

    &-link {
      --_color: #{$sys-color-error};
      --_bg-color: transparent;
      --_padding: 0 #{spacing(1)};
    }

    &-tonal {
      --_color: #{$sys-color-on-error-container};
      --_bg-color: #{$sys-color-error-container};
    }

    &-elevated {
      @extend %elevation-level1;

      --_color: #{$sys-color-on-error};
      --_bg-color: #{$sys-color-error};
    }
  }

  &--neutral {
    &-filled {
      --_color: #{$sys-color-inverse-on-surface};
      --_bg-color: #{$sys-color-inverse-surface};
    }

    &-outline {
      --_color: #{$sys-color-inverse-surface};
      --_bg-color: #{$sys-color-surface};

      outline: spacing(px) solid $sys-color-outline_variant;
    }

    &-text {
      --_color: #{$sys-color-inverse-surface};
      --_bg-color: transparent;
    }

    &-link {
      --_color: #{$sys-color-inverse-surface};
      --_bg-color: transparent;
      --_padding: 0 #{spacing(1)};
    }

    &-tonal {
      --_color: #{$sys-color-inverse-surface};
      --_bg-color: #{$sys-color-surface};
    }

    &-elevated {
      @extend %elevation-level1;

      --_color: #{$sys-color-inverse-surface};
      --_bg-color: #{$sys-color-surface};
    }
  }
}
