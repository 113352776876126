@use "../01-abstracts" as *;
@use "../02-tools" as *;

.truncate {
  @extend %truncate;
}

.break-word {
  word-break: break-word;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-wrap {
  text-wrap: balance;
}

.text-no-wrap {
  text-wrap: nowrap;
}

// Icon Alignment

.vertical-bottom {
  vertical-align: bottom;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-top {
  vertical-align: top;
}

// Text colors
.text--dimmed {
  color: $sys-color-outline;
}

.text--error {
  color: $sys-color-error;
}

.text--on-background {
  color: $sys-color-on_background;
}

.text--on-surface-variant {
  color: $sys-color-on_surface_variant;
}

.text--alternative {
  color: $sys-color-alternative;
}

.text--on-alternative {
  color: $sys-color-on_alternative;
}

.text--on-alternative-container {
  color: $sys-color-on_alternative_container;
}

.text--on-alternative-fixed {
  color: $sys-color-on_alternative_fixed;
}

.text--on-alternative-fixed-variant {
  color: $sys-color-on_alternative_fixed_variant;
}

.text--on-error {
  color: $sys-color-on_error;
}

.text--on-error-container {
  color: $sys-color-on_error_container;
}

.text--primary {
  color: $sys-color-primary;
}

.text--on-primary {
  color: $sys-color-on_primary;
}

.text--on-primary-container {
  color: $sys-color-on_primary_container;
}

.text--on-primary-fixed {
  color: $sys-color-on_primary_fixed;
}

.text--on-primary-fixed-variant {
  color: $sys-color-on_primary_fixed_variant;
}

.text--secondary {
  color: $sys-color-secondary;
}

.text--on-secondary {
  color: $sys-color-on_secondary;
}

.text--on-secondary-container {
  color: $sys-color-on_secondary_container;
}

.text--on-secondary-fixed {
  color: $sys-color-on_secondary_fixed;
}

.text--on-secondary-fixed-variant {
  color: $sys-color-on_secondary_fixed_variant;
}

.text--on-surface {
  color: $sys-color-on_surface;
}

.text--tertiary {
  color: $sys-color-tertiary;
}

.text--on-tertiary {
  color: $sys-color-on_tertiary;
}

.text--on-tertiary-container {
  color: $sys-color-on_tertiary_container;
}

.text--on-tertiary-fixed {
  color: $sys-color-on_tertiary_fixed;
}

.text--on-tertiary-fixed-variant {
  color: $sys-color-on_tertiary_fixed_variant;
}

.text--inverse-on-surface {
  color: $sys-color-inverse_on_surface;
}
