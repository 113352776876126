@use "../01-abstracts" as *;

.nax-hero-carousel {
  &__image {
    --animation-duration: 10000;

    $conversion-to-seconds: 0.001s;
    $half-cycle: 0.5;

    z-index: -1;
    object-fit: cover;
    animation: zoom linear
      calc(var(--animation-duration) * $conversion-to-seconds * $half-cycle)
      infinite alternate;

    @keyframes zoom {
      0% {
        scale: 1;
      }

      100% {
        scale: 1.05;
      }
    }
  }

  &__footer {
    background: linear-gradient(
      0deg,
      hsl(from $sys-color-on-surface h s l / 20%) 0%,
      hsl(from $sys-color-on-surface h s l / 18%) 82%,
      hsl(from $sys-color-on-surface h s l / 0%) 100%
    );
    backdrop-filter: blur(spacing("px"));
  }
}
