@use "../01-abstracts" as *;

@mixin grid-auto-fit-48 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(spacing(48), 1fr));
}

@mixin grid-auto-fill-48 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(spacing(48), 1fr));
}

@mixin grid-auto-fit-72 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(spacing(72), 1fr));
}

@mixin grid-auto-fill-72 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(spacing(72), 1fr));
}

@mixin even-columns-strict {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  & > * {
    min-width: 0;
  }
}

@mixin even-columns-responsive {
  display: grid;

  @include for-breakpoint("s") {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
}
