@use "../01-abstracts" as *;

.nax-article-header {
  &:has(+ .nax-stepper) {
    header {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
