@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-dropdown {
  position: relative;

  &__content {
    @extend %elevation-level3;
    @include rounded--large;

    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: clip;
    pointer-events: all;
    background-color: $sys-color-surface;
  }

  &__overlay {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100dvh;
  }
}
