@use "../01-abstracts" as *;
@use "../06-objects" as *;

// TASK_CLICKUP -> https://app.clickup.com/t/8696655g0
// legend text color for polygons that remain on the rise -> https://app.clickup.com/t/86c0p76up
.text--existing-polygons {
  color: #dedede;
}

.t-add-layer-map {
  display: block;
  height: spacing(64);
}

// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
.nax-ambient-range-input {
  &__diagram {
    --_ranges: 0;

    display: grid;
    grid-template-rows: auto spacing(8) auto;
    grid-template-columns: spacing(8) var(--_ranges) spacing(8);
    grid-auto-flow: column;

    @include for-orientation("portrait") {
      grid-template-rows: spacing(8) var(--_ranges) spacing(8);
      grid-template-columns: max-content spacing(10) max-content;
      grid-auto-flow: row;
      height: 60dvh;
      transform: scaleY(-1);
    }
  }

  &__range-container {
    --_backGround: #fff;
    --_range-width: 0;

    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 3;

    &:hover,
    &:focus-within {
      z-index: 9999;
    }

    @include for-orientation("portrait") {
      grid-template-columns: subgrid;
      grid-row: auto;
      grid-column: span 3;
      transform: scaleY(-1);
    }
  }

  &__range-bar {
    position: relative;
    background: var(--_backGround);
  }

  &__range-start,
  &__range-end {
    grid-row: 2;

    @include for-orientation("portrait") {
      grid-row: auto;
      grid-column: 2;
      transform: scaleY(-1);
    }
  }

  &__range-start {
    border-radius: spacing(4) 0 0 spacing(4);

    @include for-orientation("portrait") {
      border-radius: 0 0 spacing(4) spacing(4);
    }
  }

  &__range-end {
    border-radius: 0 spacing(4) spacing(4) 0;

    @include for-orientation("portrait") {
      border-radius: spacing(4) spacing(4) 0 0;
    }
  }

  &__input-color {
    position: absolute;
    inset: 0;
    opacity: 0;

    input {
      width: 100%;
      height: 100%;
    }
  }

  %triangle-shape {
    display: block;
    width: spacing(3);
    min-width: spacing(3);
    aspect-ratio: 1;
    margin: auto;
    content: "";
  }

  &__input-number {
    place-self: flex-end;
    max-width: spacing(14); // tododelete
    transform: translate(50%);

    input {
      text-align: center;
    }

    &::before {
      @extend %triangle-shape;

      background-color: var(--sys-color-on_surface_variant);
      clip-path: polygon(0 75%, 50% 10%, 100% 75%);

      @include for-orientation("portrait") {
        clip-path: polygon(75% 100%, 10% 50%, 75% 0);
      }
    }

    @include for-orientation("portrait") {
      align-self: flex-start;
      transform: translate(0, -50%);
    }
  }

  &__input-text {
    &::after {
      @extend %triangle-shape;

      background-color: var(--_backGround);
      clip-path: polygon(0 25%, 50% 90%, 100% 25%);

      @include for-orientation("portrait") {
        clip-path: polygon(10% 0, 75% 50%, 10% 100%);
      }
    }
  }

  &__input-number,
  &__input-text {
    @include for-orientation("portrait") {
      display: flex;
      align-items: center;
    }
  }
}
