@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-map {
  .map-container {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  &__loading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-pane {
    z-index: 1;
  }

  .leaflet-div-icon.marker {
    background-color: transparent;
    border: none;
  }

  .marker-info__label {
    @include body--small;

    color: $sys-color-on-surface;
    background-color: $sys-color-surface;
    border: none;
    border-radius: spacing(2);

    &.custom-tooltip {
      &::before {
        margin-left: 0;
        border: none;
        border-right-color: transparent;
      }
    }
  }

  .marker__label-id {
    width: max-content;

    // ! important added because leaflet add style into element directly
    width: inherit !important;
    height: inherit !important;
    font-size: size(14);
    color: var(--color-primary-invert);
    text-align: center;
    background-color: rgba(0 0 0 / 50%);
  }

  .marker-cluster-group__transparent-icon {
    opacity: 0;
  }

  .leaflet-marker-icon {
    &.marker-cluster {
      &-small,
      &-small > div {
        color: $sys-color-on-primary;
        background-color: color-mix(
          in srgb,
          $sys-color-primary 60%,
          transparent
        );
      }

      &-medium,
      &-medium > div {
        color: $sys-color-on-tertiary;
        background-color: color-mix(
          in srgb,
          $sys-color-tertiary 60%,
          transparent
        );
      }

      &-large,
      &-large > div {
        color: $sys-color-on-alternative;
        background-color: color-mix(
          in srgb,
          $sys-color-alternative 60%,
          transparent
        );
      }
    }
  }
}
