@use "../01-abstracts" as *;
@use "../02-tools" as *;

nax-products-selector {
  max-height: 75dvh;
}

.t-recent-products {
  @extend %scrollbar-hidden;
  /* stylelint-disable-next-line unit-allowed-list */
  max-width: 89dvw;
  overflow-x: auto;

  nax-input-chip button {
    width: max-content !important;
  }
}

.nax-map-thumbnail {
  position: absolute;
  top: 0;
  right: 0;
  width: spacing(56);
  height: spacing(64);
  padding: spacing(4);

  .nax-map > .map-container {
    @include rounded--small;

    border: spacing(px) solid $sys-color-outline;
  }
}

.t-drawer-selectors {
  @include for-orientation("portrait") {
    @include even-columns-strict;
  }

  @include for-orientation("landscape") {
    @include grid-auto-fill-48;
  }
}

.t-scroll-to-top {
  &__button {
    margin-right: spacing(4);
  }

  &__container {
    position: sticky;
    bottom: spacing(8);
    display: flex;
    justify-content: end;
  }
}

.nax-root {
  &__offline-indicator {
    position: fixed;
    top: spacing(4);
    right: 48%;
    z-index: 999;
    display: block;
  }
}

.nax-page-lock {
  &__backdrop {
    position: fixed;
    inset: 0;
    z-index: 9998;
    display: grid;
    place-items: center;
    background-color: hsl(from $sys-color-on-surface h s l / 40%);
    backdrop-filter: blur(spacing(1));
  }

  &__content {
    width: spacing(96);
    min-height: spacing(32);
  }
}

.t-nax-legend {
  &__cloud-tag {
    --cloud-color: white;

    max-width: spacing(16);
    padding: spacing(1) spacing("0_5");
    margin-inline: auto 0;
    background-color: $sys-color-inverse_primary;

    &::after {
      @include rounded--full;

      width: spacing("3_5");
      height: spacing("3_5");
      content: "";
      background-color: var(--cloud-color);
      border: spacing(px) solid $sys-color-outline;
    }
  }
}

.t-cloud-indicator {
  @include label--large;

  --cloud-indicator-color: transparent; // Coming from backend
  --text-shadow-color: #33415346; // Provisional solution

  color: $ref-color-primary-99;
  text-shadow:
    spacing("px") spacing("px") 0 var(--text-shadow-color),
    -(spacing("px")) spacing("px") 0 var(--text-shadow-color),
    spacing("px") 0 0 var(--text-shadow-color),
    0 spacing("px") 0 var(--text-shadow-color),
    -(spacing("px")) 0 0 var(--text-shadow-color);

  .p-datepicker-day:hover:not(.p-datepicker-day-selected) & {
    color: $ref-color-neutral-35; // NgPrime Calendar is not subject to themes, it must be styled directly
    text-shadow: none;
  }

  .p-datepicker-day-selected:has(&) {
    background-color: transparent;
    box-shadow: 0 0 spacing("0_5") spacing("0_5") #005ccd;
  }

  &::before {
    @include rounded--full;

    position: absolute;
    inset: -(spacing(4));
    z-index: -1;
    content: "";
    background-color: var(--cloud-indicator-color);
  }

  .p-disabled:has(&) {
    opacity: unset;
    filter: grayscale(100%);
  }
}

.p-datepicker-day-cell:has(.t-low-quality-indicator) {
  position: relative;

  &::after {
    @include rounded--full;

    position: absolute;
    top: spacing(1);
    right: spacing(1);
    width: spacing(2);
    height: spacing(2);
    content: "";
    background-color: $sys-color-error;
    border: spacing("px") solid $ref-color-neutral-100; // NgPrime Calendar is not subject to themes, it must be styled directly
  }
}
