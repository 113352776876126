@use "../01-abstracts" as *;
@use "../02-tools" as *;

.table-multiselect-bar {
  @include rounded--medium;

  display: flex;
  align-items: center;
  height: spacing(10);
  padding: spacing(2) spacing(3);
  background-color: var(--sys-color-primary_container);
}
