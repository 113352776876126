@use "../01-abstracts" as *;

hr {
  height: spacing("px");
  margin: spacing("px") 0;
  background-color: $sys-color-outline_variant;
  border: none;

  &.vertical {
    width: spacing("px");
    height: spacing(4);
    margin: 0 spacing("px");
  }
}
