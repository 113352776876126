@use "../../01-abstracts" as *;
@use "../../02-tools" as *;

p-checkbox {
  .p-checkbox-label {
    word-break: break-word;
  }

  .p-checkbox {
    width: spacing(4);
    height: spacing(4);

    &-box {
      width: spacing(4);
      height: spacing(4);
      border-color: $sys-color-inverse_surface;
      border-radius: $sys-shape-corner-extra_small;

      @include user-action-states(
        $sys-color-primary,
        $sys-color-primary_container
      );
    }

    // checked state
    &-checked:not(.p-disabled) .p-checkbox-box {
      background-color: $sys-color-primary;
      border-color: $sys-color-primary;
    }
  }

  .p-focus {
    box-shadow: none;
  }

  .p-checkbox-icon {
    width: spacing("2_5");
    height: spacing("2_5");
    transition: none;
  }

  label {
    @include label--medium;
  }
}
