@use "../01-abstracts" as *;

// Parent angular component containing chart has to have display: block for proper chart responsiveness
[class$="-chart"] {
  display: block;
}

.o-chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(spacing(150), 100%), 1fr));
}

// To be applied over chart container to make it responsive. Item must be in a Defined Container for query to work (such as drawer)
.o-double-chart {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @container (min-width: 600px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    align-items: end;
  }
}
