@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-media {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    // Arbitrary values. Might be eliminated if designers provide sufficient documentation.
    &--sm {
      height: spacing(48);
    }

    &--md {
      height: spacing(80);
    }

    &--lg {
      height: spacing(110);
    }

    &--pixelated {
      image-rendering: pixelated;
    }
  }

  &.o-full-screen img {
    @include rounded--none;

    object-fit: contain;
  }
}
