/* stylelint-disable property-disallowed-list */

@font-face {
  font-family: Inter;
  font-weight: 400 500;
  src:
    local("Inter"),
    url("./../../../public/fonts/inter/inter-variable.woff2") format("woff2")
      tech(variations);
  font-display: swap;
}
