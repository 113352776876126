@use "../01-abstracts" as *;

.portrait-only {
  @include for-orientation(landscape) {
    display: none !important;
  }
}

.landscape-only {
  @include for-orientation(portrait) {
    display: none !important;
  }
}
