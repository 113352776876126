@use "../01-abstracts" as *;

%max-w-screen-m {
  width: min($layout-size-m, 100%);
  margin-inline: auto;
}

%max-w-screen-l {
  width: min($layout-size-l, 100%);
  margin-inline: auto;
}

// Frequent layout is scrollable content containing children that should not exceed the max-w-screen-m. If you place "max-w-screen-m" class on the parent the scrollbar will be awkwardly placed on the parent. This class is for the children.
%max-w-screen-m--children {
  & > * {
    @extend %max-w-screen-m;
  }
}

%max-w-screen-l--children {
  & > * {
    @extend %max-w-screen-l;
  }
}
