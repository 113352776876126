@use "../01-abstracts" as *;

.nax-login {
  background-image: url("./../../../public/images/login_768_1024_72.webp");

  @include for-breakpoint("s") {
    background-image: url("./../../../public/images/login_1920_1080_72.webp");
  }

  @include for-breakpoint("xl") {
    background-image: url("./../../../public/images/login_2560_1440_72.webp");
  }

  @include for-breakpoint("xxl") {
    background-image: url("./../../../public/images/login_3840_2160_72.webp");
  }

  &__card {
    width: spacing(96);
    height: spacing(96);
    padding: spacing(10);
  }
}
