@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-environment-indicator {
  --indicator-color: white;

  position: absolute;
  inset: 0;
  z-index: 9999;
  display: flex;
  align-items: flex-start;
  pointer-events: none;
  border: spacing("0_5") solid var(--indicator-color);
  opacity: 0.45;

  &--dev-dev-api {
    --indicator-color: #57cc99;
  }

  &--dev-stage-stage-api {
    --indicator-color: #ff9500;
  }

  &--dev-release-api {
    --indicator-color: #c0c0c0;
  }

  @include for-orientation(portrait) {
    border-width: spacing(px);
  }

  &__info {
    display: inline-flex;
    gap: spacing(1);
    align-items: center;
    height: spacing(3);
    padding-block-end: spacing(1);
    padding-inline: spacing(1) spacing(3);
    background-color: var(--indicator-color);
    border-bottom-right-radius: spacing(12);
    transform-origin: top left;
    scale: 0.8;
  }
}
