@use "../01-abstracts" as *;

.nax-smart-models-double-map {
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 100%;
  width: 100%;
  height: 100%;

  .buttons-container {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    gap: spacing(2);
    align-items: center;
    justify-content: center;
    height: 100%;
    pointer-events: none;
    transform: translate(-50%, 0);

    .nax-cta {
      .arrow-icon {
        transform: rotate(90deg);
      }
    }

    & > * {
      pointer-events: all;
    }

    .nax-smart-models-double-map-map {
      width: 100%;
      height: 100%;
    }

    .filter-replica-buttons-container {
      display: flex;
      gap: spacing(2);
    }
  }

  @include for-breakpoint("l") {
    grid-template-rows: 100%;
    grid-template-columns: repeat(2, 1fr);

    .buttons-container {
      flex-direction: column;

      .nax-cta {
        .arrow-icon {
          transform: rotate(0);
        }
      }
    }
  }
}
