// "@content" currently used in user-action-states mixin only for ng prime overrides. May become obsolete in the future.

@use "tokens-state" as *;
@use "tokens-color" as *;

@mixin user-action-states($color, $background) {
  @include state-hover($color, $background) {
    @content;
  }

  @include state-active($color, $background) {
    @content;
  }

  // @include state-focus($color, $background) {
  //   @content;
  // }

  @include state-disabled($color, $background) {
    @content;
  }
}

@mixin state-color-mix($color, $background, $state) {
  // color: color-mix(in srgb, $background $state, $color);
  background: color-mix(in srgb, $color $state, $background);
}

@mixin state-hover($color, $background) {
  &:hover {
    @include state-color-mix($color, $background, $state-hover);
    @content;
  }
}

@mixin state-active($color, $background) {
  &:active,
  &--active {
    @include state-color-mix($color, $background, $state-active);
    @content;
  }
}

@mixin state-focus($color, $background) {
  &:focus {
    @include state-color-mix($color, $background, $state-focus);
    @content;
  }
}

@mixin state-disabled($color, $background) {
  &:disabled,
  &--disabled {
    $color-mix: color-mix(in srgb, $background $state-disabled, $color);

    // Case of disabled is different to other states. Background color is mix of background color with --surface-dim. Text color is calculated mixing color and background (as for other states) and then mixed with --surface-dim.
    color: color-mix(
      in srgb,
      $sys-color-surface_dim $state-disabled,
      $color-mix
    ) !important;
    pointer-events: none;

    // Transparent background is an exception for disabled state. It should be transparent.
    @if $background == transparent {
      background-color: transparent !important;
    } @else {
      background-color: color-mix(
        in srgb,
        $sys-color-surface_dim $state-disabled,
        $background
      ) !important;
    }
    @content;
  }
}

@mixin state-drag-over($background, $background-layer) {
  background: color-mix(in srgb, $background-layer $state-drag, $background);
}

@mixin artificial-user-action-states() {
  &:hover {
    filter: invert(7%);
  }

  &:active {
    filter: invert(12%);
  }

  &:disabled,
  &--disabled {
    filter: opacity($state-disabled) grayscale(100%);
  }
}
