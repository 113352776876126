@use "../01-abstracts" as *;
@use "../02-tools" as *;

.o-chip {
  @include label--large;
  @include rounded--small;

  display: flex;
  gap: spacing(2);
  align-items: center;
  padding: spacing(1) spacing(2);
  color: $sys-color-on-surface;
  white-space: nowrap;
  background-color: $sys-color-surface;
  border: spacing(px) solid $sys-color-outline_variant;

  @include user-action-states($sys-color-on-surface, $sys-color-surface);

  &--selected {
    color: $sys-color-on_primary_container;
    background-color: $sys-color-primary_container;
    border-color: $sys-color-primary;
  }
}
