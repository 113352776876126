@use "../01-abstracts" as *;
@use "../02-tools" as *;

.o-dropdown {
  @include rounded--small;

  display: flex;
  flex-direction: column;
  gap: spacing(1);
  padding: spacing(2);
  color: $sys-color-on_surface;
  background-color: $sys-color-surface_container;
  border: spacing(px) solid $sys-color-outline_variant;
}
