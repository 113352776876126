@use "../../01-abstracts" as *;
@use "../../02-tools" as *;

p-inputnumber {
  --p-inputtext-focus-border-color: #{$sys-color-outline_variant};

  &.size--small {
    height: spacing(8);
  }

  .p-inputnumber-input {
    @include body--medium;

    flex: 1;

    &::placeholder {
      color: $sys-color-outline;
    }

    @include user-action-states($sys-color-on_surface, $sys-color-surface);
  }

  .p-inputnumber-button {
    // background: var(--color-primary-brand);
    // border: var(--border);

    display: flex;
  }

  .p-inputtext:enabled:focus {
    // border-color: var(--color-primary-brand);
    box-shadow: none;
  }

  .p-inputnumber-button-up {
    // border-top-right-radius: var(--border-radius-input);
  }

  .p-inputnumber-button-down {
    // border-bottom-right-radius: var(--border-radius-input);
  }

  nax-advanced-map-filter & {
    width: spacing(24);
  }
}

nax-input-number {
  max-width: 100%;

  .p-inputwrapper {
    max-width: 100%;

    .p-inputnumber {
      max-width: 100%;

      .p-inputtext {
        max-width: 100%;
      }
    }
  }
}
