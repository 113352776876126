@use "../01-abstracts" as *;

.o-hr-after,
%o-hr-after {
  &::after {
    display: block;
    height: spacing("px");
    content: "";
    background-color: $sys-color-outline_variant;
  }
}

.o-hr-bottom,
%o-hr-bottom {
  border-bottom: spacing(px) solid var(--sys-color-outline_variant);
}
