@use "dimensions" as *;
@use "sass:string";
@use "sass:map";

$layout-size-xxs: #{size(360)};
$layout-size-xs: #{size(640)};
$layout-size-s: #{size(768)};
$layout-size-m: #{size(1024)};
$layout-size-l: #{size(1280)};
$layout-size-xl: #{size(1920)};
$layout-size-xxl: #{size(2560)};
$layout-size-xxxl: #{size(3840)};

// @mixin for-browser. Values: "safari" (default) | "firefox"
@mixin for-browser($isBrowser: "safari") {
  @if string.index($isBrowser, "safari") {
    @supports selector(:nth-child(1 of x)) {
      @content;
    }
  }

  @if string.index($isBrowser, "firefox") {
    @supports (-moz-appearance: none) {
      @content;
    }
  }
}

// @mixin for-no-handheld. Values: none
@mixin for-no-handheld {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

// @mixin for-breakpoint. Values: "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl"
$breakpoints-map: (
  "xxs": 360,
  "xs": 640,
  "s": 768,
  "m": 1024,
  "l": 1280,
  "xl": 1920,
  "xxl": 2560,
  "xxxl": 3840,
);

@mixin for-breakpoint($media-query-name: "l") {
  $min-width-breakpoint: map.get($breakpoints-map, $media-query-name);

  @if not map.has-key($breakpoints-map, $media-query-name) {
    @error "Breakpoint `#{$media-query-name}` is not valid. Use `xxs`, `xs`, `s`, `m`, `l`, `xl`, `xxl`, or `xxxl`.";
  }

  @media (width >= #{$min-width-breakpoint}px) {
    @content;
  }
}

// @mixin for-container. Values: "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl"
$containers-map: (
  "xxs": 360,
  "xs": 640,
  "s": 768,
  "m": 1024,
  "l": 1280,
  "xl": 1920,
  "xxl": 2560,
  "xxxl": 3840,
);

// for container-type: inline-size;
@mixin for-container($container-query-name: "l") {
  $min-width-container: map.get($containers-map, $container-query-name);

  @if not map.has-key($containers-map, $container-query-name) {
    @error "Container `#{$container-query-name}` is not valid. Use `xxs`, `xs`, `s`, `m`, `l`, `xl`, `xxl`, or `xxxl`.";
  }

  @container (width >= #{$min-width-container}px) {
    @content;
  }
}

// @mixin for-orientation. Values: "landscape" (default) | "portrait"
@mixin for-orientation($orientation: "landscape") {
  @if not($orientation == "landscape" or $orientation == "portrait") {
    @error "Orientation `#{$orientation}` is not valid. Use `landscape` or `portrait`.";
  }

  @media (orientation: $orientation) {
    @content;
  }
}

// @mixin for-orientation. Values: a width unit
