@use "../01-abstracts" as *;
@use "../02-tools" as *;

.o-fab {
  @extend %elevation-level1;

  position: fixed;
  bottom: 0;
  left: spacing(10);
  transform: translate(-50%, 50%);
  transform-origin: center;

  @include for-orientation(landscape) {
    display: none !important;
  }
}
