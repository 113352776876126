@use "../../01-abstracts" as *;
@use "../../02-tools" as *;
@use "./prime-global" as *;

.input-text {
  .p-inputtext {
    @extend %prime-input-common;
    @include body--medium;

    width: 100%;
    height: spacing(10);

    &::placeholder {
      color: $sys-color-outline;
    }

    &.size--small {
      height: spacing(8);
    }

    @include user-action-states($sys-color-on_surface, $sys-color-surface);
  }

  .nax-search & .p-inputtext {
    width: 100%;
  }

  .input-text__icon {
    position: absolute;
    top: 50%;
    margin-top: -(spacing("2_5"));
  }

  .p-input-icon-left > .input-text__icon--left {
    left: spacing(2);
  }

  .p-input-icon-right > .input-text__icon--right {
    right: spacing(2);
  }

  .p-input-icon-left,
  .p-input-icon-right {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .p-input-icon-left > .p-inputtext {
    padding-left: spacing(8);
  }

  .password-type__icon {
    cursor: pointer;
  }
}
