@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-marker-preview {
  &__image-preview img {
    max-height: spacing(40);
  }
}

.nax-marker-content {
  @include rounded--small;

  position: absolute;
  top: spacing(16);
  left: spacing(4);
  z-index: 2;
  max-width: spacing(96);
  padding: spacing(2) spacing(4);
  background-color: $sys-color-surface;

  &--right {
    right: spacing(16);
    left: inherit;
  }
}

.nax-lot-limit-input {
  &__suggestions-list {
    @extend %elevation-level3;

    position: fixed;
    display: flex;
    flex-direction: column;
    padding-block: spacing("1_5");
    pointer-events: all;
    background-color: $sys-color-surface;
  }

  &__suggestion {
    margin-bottom: spacing("1_5");
  }

  &__suggestion-item {
    padding-block: spacing("1_5");
    padding-inline: spacing(3) spacing(1);
    margin-bottom: spacing("1_5");
    cursor: pointer;

    &--selected {
      @include state-color-mix(
        $sys-color-on_secondary_container,
        $sys-color-surface_container,
        $state-active
      );

      @include user-action-states(
        $sys-color-on_secondary_container,
        $sys-color-surface_container
      ) {
        border-color: $sys-color-outline_variant;
      }
    }
  }
}
