@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-stepper {
  &__step-indicator-container {
    display: flex;
    gap: spacing(1);
  }

  &__step-indicator {
    @include rounded--full;

    flex: 1;
    height: spacing(1);
    background-color: $sys-color-outline-variant;

    &--selected {
      background-color: $sys-color-primary;
    }

    &--completed {
      // background-color: color-opacity($sys-color-primary, 0.5); // ! Uncomment when color-opacity is working!
      background-color: $sys-color-inverse_primary;
    }
  }

  // ! Follows same pattern as the article footer
  &__step-content:has(.max-w-screen-m) + &__step-footer,
  &__step-content:has(.max-w-screen-m--children) + &__step-footer {
    @extend %max-w-screen-m;
  }

  &__step-content:has(.max-w-screen-l) + &__step-footer,
  &__step-content:has(.max-w-screen-l--children) + &__step-footer {
    @extend %max-w-screen-l;
  }
}
