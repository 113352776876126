@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-avatar {
  --_width: 0;
  --_height: 0;

  @include rounded--full;

  display: block;
  flex-shrink: 0; // Prevents the avatar from shrinking when inside a flex container
  width: var(--_width);
  height: var(--_height);
  overflow: hidden;
  color: $sys-color-on-surface;
  background-color: $sys-color-surface-container-highest;
  border: spacing(px) solid $sys-color-outline_variant;

  img {
    object-fit: cover;
  }

  // Sizes
  &--xl {
    --_width: #{spacing(10)};
    --_height: #{spacing(10)};

    @include label--large;
  }

  &--lg {
    --_width: #{spacing(8)};
    --_height: #{spacing(8)};

    @include label--large;
  }

  &--md {
    --_width: #{spacing(6)};
    --_height: #{spacing(6)};

    @include label--small;
  }
}
