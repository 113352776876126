@use "../../01-abstracts" as *;

p-toggle-switch {
  --p-toggleswitch-checked-background: #{$sys-color-primary};
  --p-toggleswitch-checked-border-color: #{$sys-color-on-primary};
  --p-toggleswitch-checked-hover-background: #{$sys-color-primary};
  --p-toggleswitch-checked-hover-border-color: #{$sys-color-on-primary};

  display: flex;
  align-items: center;

  .p-toggleswitch-slider {
    background: $sys-color-surface-dim;

    &::before {
      background: $sys-color-on-surface-variant;
    }
  }

  .p-toggleswitch-checked .p-toggleswitch-slider {
    background-color: var(--color-primary-brand);

    &::before {
      background-color: var(--color-primary-brand-low-emphasis);
    }
  }

  .p-toggleswitch.p-focus .p-toggleswitch-slider {
    box-shadow: none;
  }
}
