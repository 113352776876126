@use "../01-abstracts" as *;
@use "../02-tools" as *;

// Height
.h-full {
  height: 100%;
}

.h-full--dvh {
  height: 100dvh;
}

.max-h-full {
  max-height: 100%;
}

.min-h-0 {
  min-height: 0;
}

// Width
// 28 For number and date inputs static width
.w-28 {
  width: spacing(28);
}

// 52 For selector static width
.w-52 {
  width: spacing(52);
}

// 80 Snackbar
.w-80 {
  width: spacing(80);
}

.w-full {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.min-w-max {
  min-width: max-content;
}

.min-w-0 {
  min-width: 0;
}

.max-w-full {
  max-width: 100%;
}
