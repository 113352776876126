/* stylelint-disable property-no-vendor-prefix */

/*
=========== Far Reaching
*/
*,
::before,
::after {
  box-sizing: border-box;
}

html {
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-text-size-adjust: 100%; /*  Prevent adjustments of font size after orientation changes in iOS. */
  tab-size: 4;
}

/*
=========== Margin Resets
*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/*
=========== Padding Resets
*/
button,
fieldset,
legend,
ul {
  padding: 0;
}

/*
=========== Forms
*/

fieldset {
  border: none;
}

button,
input,
optgroup,
select,
h1,
h2,
h3,
h4,
h5,
h6,
textarea {
  font: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

button {
  display: block;
  text-align: inherit;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;

  &:disabled {
    cursor: not-allowed;
  }
}

a {
  display: block;
  color: inherit;
  text-decoration: none;

  &--disabled {
    cursor: not-allowed;
  }
}

progress {
  vertical-align: baseline;
}

/*
=========== Other
*/

router-outlet {
  display: none;
}

small {
  font-size: 80%;
}

li {
  list-style: none;
}

iframe {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border: 0;
}

details > summary {
  list-style: none;
}
