@use "../01-abstracts" as *;
@use "../02-tools" as *;

// ---------------  Page flows are used to add margins between children. They are used to create vertical spacing between elements.
%flow {
  display: flex;
  flex-direction: column;
}

.flow-2 {
  @extend %flow;

  gap: spacing(2);
}

.flow-3 {
  @extend %flow;

  gap: spacing(3);
}

.flow-4 {
  @extend %flow;

  gap: spacing(4);
}

.flow-6 {
  @extend %flow;

  gap: spacing(6);
}

// --------------- Used to contain width of elements to a readable width.
.max-w-screen-m {
  @extend %max-w-screen-m;
}

.max-w-screen-l {
  @extend %max-w-screen-l;
}

// Frequent layout is scrollable content containing children that should not exceed the max-w-screen-m. If you place "max-w-screen-m" class on the parent the scrollbar will be awkwardly placed on the parent. This class is for the children.
.max-w-screen-m--children {
  @extend %max-w-screen-m--children;
}

.max-w-screen-l--children {
  @extend %max-w-screen-l--children;
}

// --------------- Used to create equal column layouts
.even-columns-responsive {
  @include even-columns-responsive;
}

// 48 - Primary use for pages with multiple selectors in aside
.grid-auto-fit-48 {
  @include grid-auto-fit-48;
}

.grid-auto-fill-48 {
  @include grid-auto-fill-48;
}

// 72 - Primary use for arrays of cards
.grid-auto-fit-72 {
  @include grid-auto-fit-72;
}

.grid-auto-fill-72 {
  @include grid-auto-fill-72;
}
