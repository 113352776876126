@use "../../01-abstracts" as *;

apx-chart {
  display: block;

  // max-height: spacing(128);

  // & > * {
  //   min-height: spacing(128) !important;

  //   .apexcharts-canvas {
  //     height: spacing(128) !important;
  //   }
  // }
}
