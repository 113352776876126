@use "../01-abstracts" as *;
@use "../02-tools" as *;

.o-full-screen {
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: $sys-color-surface;
  border: spacing(12) solid hsl(from $sys-color-on-surface h s l / 80%);

  &__close {
    position: fixed;
    top: spacing(9);
    right: spacing(9);
  }

  @include for-orientation("portrait") {
    border-width: spacing(4);

    &__close {
      top: spacing(2);
      right: spacing(2);
    }
  }
}
