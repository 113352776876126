@use "../01-abstracts" as *;
@use "../02-tools" as *;

.page--scrollable,
.page--contained,
.page-with-aside,
.page-with-drawer-and-map {
  flex-grow: 1;
}

.page--scrollable {
  overflow: auto;
}

.page--contained {
  overflow: hidden;
}

.page-with-drawer-and-map {
  display: flex;

  @include for-orientation("portrait") {
    flex-direction: column-reverse;
  }
}

.page-with-aside {
  display: flex;
  overflow: hidden;

  .navigation-aside {
    display: none;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
    gap: spacing(3);
    padding: spacing(3);
    background-color: $sys-color-surface;
    border-right: spacing(px) solid var(--sys-color-outline_variant);

    &__nav {
      margin-block-start: spacing(5);
    }

    &__section-title {
      color: var(--sys-color-on_surface_variant);
    }

    // Show aside if outlet has no page--scrollable
    &:not(
        :has(+ * + .page--scrollable),
        :has(+ * + .page--contained),
        :has(+ * + .page-with-drawer-and-map)
      ) {
      display: flex;
    }

    &__cta {
      @include label--large;
      @include rounded--full;

      padding: spacing(3) spacing(4);
      color: $sys-color-on-secondary-container;

      &--selected {
        background-color: $sys-color-secondary-container;
      }

      @include user-action-states(
        $sys-color-on-secondary-container,
        $sys-color-secondary-container
      );
    }
  }

  @include for-orientation("landscape") {
    .navigation-aside {
      display: flex;
      flex-grow: 0;
      flex-basis: min(spacing(96), 100%);
    }
  }
}
