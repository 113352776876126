@use "../01-abstracts" as *;
@use "../02-tools" as *;

.o-card,
%o-card {
  @include rounded--medium;

  padding: spacing(4);
  border: spacing(px) solid $sys-color-outline_variant;
}
