@use "sass:color";
@use "../01-abstracts" as *;
@use "../02-tools" as *;

$scrollbar-size: spacing(2);

* {
  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    @include rounded--full;

    background-color: $sys-color-outline;

    &:hover {
      background-color: color-mix(
        in hsl,
        $sys-color-outline,
        transparent $state-hover
      );
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: $sys-color-outline;
  }

  @include for-browser("firefox") {
    scrollbar-color: $sys-color-outline transparent;
    scrollbar-width: thin !important;
  }
}
