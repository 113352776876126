@use "../../01-abstracts" as *;

p-date-picker {
  .p-inputtext {
    height: spacing(10);
    padding-block: spacing("1_5");
    padding-inline: spacing(3) spacing(1);
    color: $sys-color-on-surface;
    outline: spacing("px") solid $sys-color-outline;
    outline-offset: -(spacing("px"));
    background-color: $sys-color-surface;
    border: none;
    border-radius: $sys-shape-corner-small;

    &::placeholder {
      color: $sys-color-outline;
    }

    &:focus {
      outline-width: spacing("0_5");
      outline-offset: -(spacing("0_5"));
      background-color: $sys-color-surface;
      box-shadow: none;
    }
  }

  &.size--small {
    .p-inputtext {
      height: spacing(8);
    }
  }
}

.p-datepicker-panel {
  --p-datepicker-date-selected-color: #{$sys-color-on-primary};
  --p-datepicker-date-selected-background: #{$sys-color-primary};
}
