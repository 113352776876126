/* stylelint-disable scss/at-mixin-pattern */
@use "../01-abstracts" as *;

@mixin rounded--extra-large {
  border-radius: $sys-shape-corner-extra_large;
}

@mixin rounded--extra-small {
  border-radius: $sys-shape-corner-extra_small;
}

@mixin rounded--full {
  border-radius: $sys-shape-corner-full;
}

@mixin rounded--large {
  border-radius: $sys-shape-corner-large;
}

@mixin rounded--medium {
  border-radius: $sys-shape-corner-medium;
}

@mixin rounded--none {
  border-radius: $sys-shape-corner-none;
}

@mixin rounded--small {
  border-radius: $sys-shape-corner-small;
}
