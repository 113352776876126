@use "../01-abstracts" as *;

%elevation-level0 {
  z-index: $sys-elevation-level0;
  box-shadow: $ref-shadow-ambient-level-0, $ref-shadow-key-level-0;
}

%elevation-level1 {
  z-index: $sys-elevation-level1;
  box-shadow: $ref-shadow-ambient-level-1, $ref-shadow-key-level-1;
}

%elevation-level2 {
  z-index: $sys-elevation-level2;
  box-shadow: $ref-shadow-ambient-level-2, $ref-shadow-key-level-2;
}

%elevation-level3 {
  z-index: $sys-elevation-level3;
  box-shadow: $ref-shadow-ambient-level-3, $ref-shadow-key-level-3;
}

%elevation-level4 {
  z-index: $sys-elevation-level4;
  box-shadow: $ref-shadow-ambient-level-4, $ref-shadow-key-level-4;
}

%elevation-level5 {
  z-index: $sys-elevation-level5;
  box-shadow: $ref-shadow-ambient-level-5, $ref-shadow-key-level-5;
}
