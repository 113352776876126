@use "../01-abstracts" as *;
@use "../02-tools" as *;

label {
  @include label--medium;

  p {
    @include label--small;

    color: $sys-color-outline;
  }
}
