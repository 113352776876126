@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-map-filter {
  &__search-dropdown {
    max-width: spacing(128);
    padding: spacing(4);
    background-color: $sys-color-surface;
  }
}
