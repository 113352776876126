@use "../01-abstracts" as *;

.nax-sidenav {
  @include for-orientation("portrait") {
    border-top: spacing(px) solid $sys-color-outline_variant;
  }

  @include for-orientation("landscape") {
    border-right: spacing(px) solid $sys-color-outline_variant;
  }

  .rail-nav {
    display: flex;
    gap: spacing(3);
    align-items: center;
    height: spacing(18);
    padding: spacing(2) spacing(3);
    background-color: $sys-color-surface_container_lowest;

    @include for-orientation("landscape") {
      flex-direction: column;
      gap: spacing(10);
      justify-items: center;
      width: spacing(20);
      height: 100%;
      padding: spacing(4) 0 spacing(14);
    }
  }

  .main-list-container {
    position: relative;
    flex-grow: 1;
    overflow: hidden;

    .list__cta {
      width: 100%;
    }
  }

  .list {
    display: flex;
    gap: spacing(4);
    justify-content: safe center;
    height: 100%;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &__cta {
      // width: 100%;

      .list__icon-container {
        width: spacing(14);
        height: spacing(8);

        @include user-action-states(
          $sys-color-primary,
          $sys-color-primary-container
        );
      }

      &--selected {
        color: $sys-color-primary;

        .list__icon-container {
          background-color: $sys-color-primary-container;
        }
      }

      &--disabled {
        color: hsl(from $sys-color-on-surface h s l / $state-disabled);
      }
    }

    @include for-orientation("landscape") {
      flex-direction: column;
      justify-content: start;
      width: spacing(20);
      padding-inline: spacing("1_5");
      scrollbar-width: unset;

      &__footer {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    @include for-orientation("portrait") {
      // Scroll curtains config ↓↓↓
      scroll-timeline-name: --item-list;
      scroll-timeline-axis: x;

      &::before,
      &::after {
        position: absolute;
        width: spacing(8);
        height: 100%;
        pointer-events: none;
        content: "";
        animation-name: curtains-scroll-animation;
        animation-timeline: --item-list;
      }

      &::before {
        top: 0;
        left: 0;
        background: linear-gradient(
          90deg,
          var(--sys-color-surface_container_lowest) 25%,
          transparent 100%
        );
        opacity: 0;
      }

      &::after {
        top: 0;
        right: 0;
        background: linear-gradient(
          90deg,
          transparent 0%,
          var(--sys-color-surface_container_lowest) 75%
        );
        animation-direction: reverse;
      }
    }

    &__item {
      &:last-child {
        margin-right: spacing(16);
      }

      @include for-orientation("landscape") {
        &:last-child {
          margin-right: 0;
        }
      }

      &:nth-of-type(3n + 1) {
        scroll-snap-align: start;
      }
    }
  }

  @keyframes curtains-scroll-animation {
    0% {
      opacity: 0;
    }

    5% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }
}
