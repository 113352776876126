@use "../01-abstracts" as *;

.nax-input-forms {
  position: relative;
  width: 100%;
  height: max-content;
  margin-inline: auto;
  overflow: hidden;

  .table-responsive {
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }

  table {
    overflow: auto;
  }

  th {
    position: sticky;
    top: 0;
    min-width: size(150);
    max-width: size(200);
    min-height: size(64);
    padding: spacing(2) spacing(1);
    color: var(--color-primary);
    text-align: center;
    background-color: var(--background-color-medium-emphasis);
    border-bottom: var(--border);
  }

  thead th:last-child {
    position: sticky;
    right: 0;
    min-width: size(150);
  }

  td {
    min-width: size(150);
    max-width: size(200);
    min-height: size(64);
    padding: spacing(2) spacing(1);
    margin-inline: auto;
    color: var(--color-primary);
    text-align: center;
    background-color: var(--background-color-medium-emphasis);
    border-bottom: var(--border);
  }

  tbody td:last-child {
    position: sticky;
    right: 0;
    min-width: size(150);

    .nax-icon {
      width: spacing(6);
      height: spacing(6);
    }
  }
}
