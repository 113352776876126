@use "../01-abstracts" as *;

:root {
  // To be deleted ↓
  @each $color-name, $color-value in $colors-map {
    --color-#{$color-name}: #{$color-value};
  }

  @each $element-name, $element-value in $elements-map {
    --#{$element-name}: #{$element-value};
  }

  @each $z-index-name, $z-index-value in $z-index-map {
    --z-index-#{$z-index-name}: #{$z-index-value};
  }

  // To be deleted ↑

  /* ----------------------***-------------------------- 
 ________ Color 
 -------------------------***-------------------------- */
  color-scheme: light dark;

  --sys-color-alternative: #{$ref-color-alternative-30};
  --sys-color-alternative_container: #{$ref-color-alternative-95};
  --sys-color-alternative_fixed: #{$ref-color-alternative-90};
  --sys-color-alternative_fixed_dim: #{$ref-color-alternative-80};
  --sys-color-background: #{$ref-color-neutral-100};
  --sys-color-error: #{$ref-color-error-40};
  --sys-color-error_container: #{$ref-color-error-95};
  --sys-color-inverse_on_surface: #{$ref-color-neutral-95};
  --sys-color-inverse_primary: #{$ref-color-primary-80};
  --sys-color-inverse_surface: #{$ref-color-neutral-20};
  --sys-color-on_alternative: #{$ref-color-alternative-100};
  --sys-color-on_alternative_container: #{$ref-color-alternative-10};
  --sys-color-on_alternative_fixed: #{$ref-color-alternative-10};
  --sys-color-on_alternative_fixed_variant: #{$ref-color-alternative-30};
  --sys-color-on_background: #{$ref-color-neutral-5};
  --sys-color-on_error: #{$ref-color-error-100};
  --sys-color-on_error_container: #{$ref-color-error-10};
  --sys-color-on_primary: #{$ref-color-primary-100};
  --sys-color-on_primary_container: #{$ref-color-primary-10};
  --sys-color-on_primary_fixed: #{$ref-color-primary-10};
  --sys-color-on_primary_fixed_variant: #{$ref-color-primary-30};
  --sys-color-on_secondary: #{$ref-color-secondary-100};
  --sys-color-on_secondary_container: #{$ref-color-secondary-10};
  --sys-color-on_secondary_fixed: #{$ref-color-secondary-10};
  --sys-color-on_secondary_fixed_variant: #{$ref-color-secondary-30};
  --sys-color-on_surface: #{$ref-color-neutral-10};
  --sys-color-on_surface_variant: #{$ref-color-neutral_variant-30};
  --sys-color-on_tertiary: #{$ref-color-tertiary-100};
  --sys-color-on_tertiary_container: #{$ref-color-tertiary-10};
  --sys-color-on_tertiary_fixed: #{$ref-color-tertiary-10};
  --sys-color-on_tertiary_fixed_variant: #{$ref-color-tertiary-30};
  --sys-color-outline: #{$ref-color-neutral_variant-60};
  --sys-color-outline_variant: #{$ref-color-neutral_variant-90};
  --sys-color-primary: #{$ref-color-primary-40};
  --sys-color-primary_container: #{$ref-color-primary-95};
  --sys-color-primary_fixed: #{$ref-color-primary-90};
  --sys-color-primary_fixed_dim: #{$ref-color-primary-80};
  --sys-color-scrim: #{$ref-color-neutral-0};
  --sys-color-secondary: #{$ref-color-secondary-40};
  --sys-color-secondary_container: #{$ref-color-secondary-95};
  --sys-color-secondary_fixed: #{$ref-color-secondary-90};
  --sys-color-secondary_fixed_dim: #{$ref-color-secondary-80};
  --sys-color-shadow: #{$ref-color-neutral-0};
  --sys-color-surface: #{$ref-color-neutral-98};
  --sys-color-surface_bright: #{$ref-color-neutral-98};
  --sys-color-surface_container: #{$ref-color-neutral-98};
  --sys-color-surface_container_high: #{$ref-color-neutral-95};
  --sys-color-surface_container_highest: #{$ref-color-neutral-95};
  --sys-color-surface_container_low: #{$ref-color-neutral-99};
  --sys-color-surface_container_lowest: #{$ref-color-neutral-100};
  --sys-color-surface_dim: #{$ref-color-neutral-80};
  --sys-color-tertiary: #{$ref-color-tertiary-40};
  --sys-color-tertiary_container: #{$ref-color-tertiary-95};
  --sys-color-tertiary_fixed: #{$ref-color-tertiary-90};
  --sys-color-tertiary_fixed_dim: #{$ref-color-tertiary-80};
}

[data-theme="dark"] {
  &:root {
    --sys-color-alternative: #{$ref-color-alternative-70};
    --sys-color-alternative_container: #{$ref-color-alternative-20};
    --sys-color-alternative_fixed: #{$ref-color-alternative-90};
    --sys-color-alternative_fixed_dim: #{$ref-color-alternative-80};
    --sys-color-background: #{$ref-color-neutral-0};
    --sys-color-error: #{$ref-color-error-80};
    --sys-color-error_container: #{$ref-color-error-25};
    --sys-color-inverse_on_surface: #{$ref-color-neutral-20};
    --sys-color-inverse_primary: #{$ref-color-primary-40};
    --sys-color-inverse_surface: #{$ref-color-neutral-90};
    --sys-color-on_alternative: #{$ref-color-alternative-20};
    --sys-color-on_alternative_container: #{$ref-color-alternative-90};
    --sys-color-on_alternative_fixed: #{$ref-color-alternative-10};
    --sys-color-on_alternative_fixed_variant: #{$ref-color-alternative-30};
    --sys-color-on_background: #{$ref-color-neutral-95};
    --sys-color-on_error: #{$ref-color-error-20};
    --sys-color-on_error_container: #{$ref-color-error-90};
    --sys-color-on_primary: #{$ref-color-primary-20};
    --sys-color-on_primary_container: #{$ref-color-primary-90};
    --sys-color-on_primary_fixed: #{$ref-color-primary-10};
    --sys-color-on_primary_fixed_variant: #{$ref-color-primary-30};
    --sys-color-on_secondary: #{$ref-color-secondary-20};
    --sys-color-on_secondary_container: #{$ref-color-secondary-90};
    --sys-color-on_secondary_fixed: #{$ref-color-secondary-10};
    --sys-color-on_secondary_fixed_variant: #{$ref-color-secondary-30};
    --sys-color-on_surface: #{$ref-color-neutral-90};
    --sys-color-on_surface_variant: #{$ref-color-neutral_variant-80};
    --sys-color-on_tertiary: #{$ref-color-tertiary-20};
    --sys-color-on_tertiary_container: #{$ref-color-tertiary-90};
    --sys-color-on_tertiary_fixed: #{$ref-color-tertiary-10};
    --sys-color-on_tertiary_fixed_variant: #{$ref-color-tertiary-30};
    --sys-color-outline: #{$ref-color-neutral_variant-50};
    --sys-color-outline_variant: #{$ref-color-neutral_variant-25};
    --sys-color-primary: #{$ref-color-primary-80};
    --sys-color-primary_container: #{$ref-color-primary-30};
    --sys-color-primary_fixed: #{$ref-color-primary-90};
    --sys-color-primary_fixed_dim: #{$ref-color-primary-80};
    --sys-color-scrim: #{$ref-color-neutral-0};
    --sys-color-secondary: #{$ref-color-secondary-80};
    --sys-color-secondary_container: #{$ref-color-secondary-25};
    --sys-color-secondary_fixed: #{$ref-color-secondary-90};
    --sys-color-secondary_fixed_dim: #{$ref-color-secondary-80};
    --sys-color-shadow: #{$ref-color-neutral-0};
    --sys-color-surface: #{$ref-color-neutral-6};
    --sys-color-surface_bright: #{$ref-color-neutral-24};
    --sys-color-surface_container: #{$ref-color-neutral-12};
    --sys-color-surface_container_high: #{$ref-color-neutral-17};
    --sys-color-surface_container_highest: #{$ref-color-neutral-22};
    --sys-color-surface_container_low: #{$ref-color-neutral-10};
    --sys-color-surface_container_lowest: #{$ref-color-neutral-4};
    --sys-color-surface_dim: #{$ref-color-neutral-25};
    --sys-color-tertiary: #{$ref-color-tertiary-80};
    --sys-color-tertiary_container: #{$ref-color-tertiary-25};
    --sys-color-tertiary_fixed: #{$ref-color-tertiary-90};
    --sys-color-tertiary_fixed_dim: #{$ref-color-tertiary-80};
  }
}
