@use "../01-abstracts" as *;
@use "../02-tools" as *;

body {
  @include body--medium;

  overscroll-behavior-y: none;
  font-family: $font-base;
  font-feature-settings: initial;
  color: $sys-color-on_surface;
  background-color: $sys-color-surface_container_lowest;
}
