@use "../01-abstracts" as *;
@use "../02-tools" as *;

.visually-hidden:not(:focus, :active) {
  position: fixed;
  left: spacing(infinite);
  width: spacing(px);
  height: spacing(px);
  overflow: hidden;
  pointer-events: none;
}

.scrollbar-hidden {
  @extend %scrollbar-hidden;
}
