@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-input-file {
  background-color: $sys-color-secondary_container;
  container-type: inline-size;
  border: spacing("0_5") dashed $sys-color-secondary;

  &--drag-over {
    @include state-drag-over(
      $sys-color-secondary_container,
      $sys-color-secondary
    );
  }

  &__icon {
    @include for-container(xs) {
      .nax-icon {
        width: spacing(18);
        height: spacing(18);
      }
    }
  }

  &__loaded-file {
    padding-block: spacing(1);
    padding-inline: spacing("1_5");
  }
}
