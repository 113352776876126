@use "../01-abstracts" as *;

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.wrap {
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grow,
.spacer {
  flex-grow: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.basis-full {
  flex-basis: 100%;
}

// -------------- Item Position --------------

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

// -------------- Gap --------------

.gap-1 {
  gap: spacing(1);
}

.gap-2 {
  gap: spacing(2);
}

.gap-3 {
  gap: spacing(3);
}

.gap-4 {
  gap: spacing(4);
}

.gap-5 {
  gap: spacing(5);
}

.gap-6 {
  gap: spacing(6);
}

.gap-8 {
  gap: spacing(8);
}
