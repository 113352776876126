@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-drawer {
  @extend %elevation-level1;

  position: relative;
  z-index: 3;
  container-type: inline-size;

  @include for-orientation("portrait") {
    position: absolute;
    background-color: $sys-color-surface;
  }

  &:has(.nax-drawer__content:empty) {
    display: none;
  }

  &__overflow-mask {
    display: flex;
    height: 100%;
    overflow: clip;
  }

  &__content {
    flex-grow: 1;
    min-width: spacing(80);
    padding: spacing(2);
    overflow-y: auto;
  }

  .nax-drawer__drag-element {
    position: absolute;
    top: 50%;
    right: 0;
    width: spacing(5);
    height: spacing(9);
    background-color: $sys-color-surface;
    border-top: spacing(px) solid $sys-color-outline_variant;
    border-right: spacing(px) solid $sys-color-outline_variant;
    border-bottom: spacing(px) solid $sys-color-outline_variant;
    border-radius: 0 $sys-shape-corner-small $sys-shape-corner-small 0;
    transform: translate(100%, -50%);

    @include user-action-states($sys-color-on-surface, $sys-color-surface);

    @include for-orientation("portrait") {
      top: -(spacing("2_5"));
      right: 100%;
      width: 100%;
      height: spacing(7);
      border-bottom: none;
      border-radius: $sys-shape-corner-full $sys-shape-corner-full 0 0;

      .nax-icon {
        display: none;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: spacing(8);
        height: spacing(1);
        content: "";
        background-color: $sys-color-outline;
        border-radius: $sys-shape-corner-full;
        transform: translate(-50%, -50%);
      }
    }
  }
}
