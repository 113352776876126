@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-badge {
  position: relative;
  width: max-content;

  &__badge {
    --_color: transparent;
    --_bg-color: transparent;
    --_padding: 0;

    @include rounded--full;

    position: absolute;
    top: 0;
    right: 0;
    padding: var(--_padding);
    color: var(--_color);
    background-color: var(--_bg-color);

    &--primary {
      --_color: #{$sys-color-on-primary};
      --_bg-color: #{$sys-color-primary};
    }

    &--secondary {
      --_color: #{$sys-color-on-secondary};
      --_bg-color: #{$sys-color-secondary};
    }

    &--success {
      --_color: #{$sys-color-on-tertiary};
      --_bg-color: #{$sys-color-tertiary};
    }

    &--error {
      --_color: #{$sys-color-on-error};
      --_bg-color: #{$sys-color-error};
    }

    &--neutral {
      --_color: #{$sys-color-inverse-on-surface};
      --_bg-color: #{$sys-color-inverse-surface};
    }

    &--md {
      --_padding: #{spacing("0_5") spacing(2)};

      @include label--medium;

      transform: translate(40%, -50%);
    }

    &--sm {
      --_padding: 0 #{spacing(1)};

      @include label--small;

      min-width: spacing(4);
      transform: translate(35%, -40%);
    }

    &--xs {
      --_padding: 0;

      width: #{spacing("1_5")};
      height: #{spacing("1_5")};
      content-visibility: hidden;
      transform: translate(-20%, 20%);
    }
  }
}
