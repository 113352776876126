@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-legend-maps {
  display: flex;
  gap: spacing(4);
  justify-content: center;

  .nax-map {
    @include rounded--medium;

    flex-grow: 1;
    max-width: spacing(80);
    height: spacing(110);
    overflow: clip;
  }
}
