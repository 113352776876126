$ref-color-alternative-0: hsl(0deg 0% 0%);
$ref-color-alternative-10: hsl(32deg 94% 27%);
$ref-color-alternative-100: hsl(0deg 0% 100%);
$ref-color-alternative-20: hsl(32deg 95% 37%);
$ref-color-alternative-30: hsl(32deg 84% 47%);
$ref-color-alternative-40: hsl(32deg 74% 58%);
$ref-color-alternative-5: hsl(32deg 94% 19%);
$ref-color-alternative-50: hsl(32deg 73% 68%);
$ref-color-alternative-60: hsl(32deg 74% 76%);
$ref-color-alternative-70: hsl(32deg 73% 83%);
$ref-color-alternative-80: hsl(31deg 75% 89%);
$ref-color-alternative-90: hsl(32deg 70% 95%);
$ref-color-alternative-95: hsl(36deg 71% 97%);
$ref-color-alternative-98: hsl(45deg 67% 99%);
$ref-color-alternative-99: hsl(30deg 100% 100%);
$ref-color-alternative_variant-0: hsl(0deg 0% 0%);
$ref-color-alternative_variant-10: hsl(54deg 83% 21%);
$ref-color-alternative_variant-100: hsl(0deg 0% 100%);
$ref-color-alternative_variant-20: hsl(54deg 84% 29%);
$ref-color-alternative_variant-30: hsl(54deg 84% 35%);
$ref-color-alternative_variant-40: hsl(54deg 84% 40%);
$ref-color-alternative_variant-5: hsl(54deg 84% 15%);
$ref-color-alternative_variant-50: hsl(54deg 84% 44%);
$ref-color-alternative_variant-60: hsl(54deg 73% 53%);
$ref-color-alternative_variant-70: hsl(54deg 74% 67%);
$ref-color-alternative_variant-80: hsl(55deg 73% 79%);
$ref-color-alternative_variant-90: hsl(55deg 72% 90%);
$ref-color-alternative_variant-95: hsl(53deg 75% 95%);
$ref-color-alternative_variant-98: hsl(51deg 78% 98%);
$ref-color-alternative_variant-99: hsl(60deg 60% 99%);
$ref-color-error-0: hsl(0deg 0% 0%);
$ref-color-error-10: hsl(358deg 100% 13%);
$ref-color-error-100: hsl(0deg 0% 100%);
$ref-color-error-15: hsl(358deg 100% 16%);
$ref-color-error-20: hsl(357deg 100% 21%);
$ref-color-error-25: hsl(357deg 100% 25%);
$ref-color-error-30: hsl(356deg 100% 29%);
$ref-color-error-35: hsl(357deg 92% 34%);
$ref-color-error-40: hsl(0deg 75% 42%);
$ref-color-error-5: hsl(359deg 100% 9%);
$ref-color-error-50: hsl(2deg 73% 53%);
$ref-color-error-60: hsl(4deg 100% 64%);
$ref-color-error-70: hsl(6deg 100% 75%);
$ref-color-error-80: hsl(6deg 100% 84%);
$ref-color-error-90: hsl(6deg 100% 92%);
$ref-color-error-95: hsl(9deg 100% 96%);
$ref-color-error-98: hsl(8deg 100% 98%);
$ref-color-error-99: hsl(300deg 100% 99%);
$ref-color-neutral-0: hsl(0deg 0% 0%);
$ref-color-neutral-10: hsl(195deg 7% 11%);
$ref-color-neutral-100: hsl(0deg 0% 100%);
$ref-color-neutral-12: hsl(187deg 16% 11%);
$ref-color-neutral-15: hsl(195deg 5% 15%);
$ref-color-neutral-17: hsl(187deg 12% 14%);
$ref-color-neutral-20: hsl(195deg 4% 19%);
$ref-color-neutral-22: hsl(189deg 7% 20%);
$ref-color-neutral-24: hsl(200deg 5% 23%);
$ref-color-neutral-25: hsl(195deg 3% 23%);
$ref-color-neutral-30: hsl(195deg 3% 27%);
$ref-color-neutral-35: hsl(195deg 2% 32%);
$ref-color-neutral-4: hsl(195deg 14% 5%);
$ref-color-neutral-40: hsl(180deg 2% 37%);
$ref-color-neutral-5: hsl(180deg 13% 6%);
$ref-color-neutral-50: hsl(180deg 2% 46%);
$ref-color-neutral-6: hsl(187deg 24% 7%);
$ref-color-neutral-60: hsl(195deg 2% 56%);
$ref-color-neutral-70: hsl(180deg 2% 67%);
$ref-color-neutral-80: hsl(180deg 3% 77%);
$ref-color-neutral-90: hsl(180deg 3% 89%);
$ref-color-neutral-95: hsl(180deg 7% 94%);
$ref-color-neutral-98: hsl(180deg 17% 98%);
$ref-color-neutral-99: hsl(180deg 43% 99%);
$ref-color-neutral_variant-0: hsl(0deg 0% 0%);
$ref-color-neutral_variant-10: hsl(196deg 21% 10%);
$ref-color-neutral_variant-100: hsl(0deg 0% 100%);
$ref-color-neutral_variant-12: hsl(196deg 24% 9%);
$ref-color-neutral_variant-15: hsl(196deg 15% 14%);
$ref-color-neutral_variant-17: hsl(195deg 19% 13%);
$ref-color-neutral_variant-20: hsl(195deg 13% 18%);
$ref-color-neutral_variant-22: hsl(198deg 10% 19%);
$ref-color-neutral_variant-24: hsl(196deg 10% 22%);
$ref-color-neutral_variant-25: hsl(195deg 10% 23%);
$ref-color-neutral_variant-30: hsl(196deg 8% 27%);
$ref-color-neutral_variant-35: hsl(195deg 7% 32%);
$ref-color-neutral_variant-4: hsl(195deg 31% 5%);
$ref-color-neutral_variant-40: hsl(195deg 6% 36%);
$ref-color-neutral_variant-5: hsl(196deg 35% 6%);
$ref-color-neutral_variant-50: hsl(200deg 5% 46%);
$ref-color-neutral_variant-6: hsl(195deg 35% 7%);
$ref-color-neutral_variant-60: hsl(195deg 5% 56%);
$ref-color-neutral_variant-70: hsl(195deg 7% 67%);
$ref-color-neutral_variant-80: hsl(195deg 10% 77%);
$ref-color-neutral_variant-90: hsl(195deg 20% 88%);
$ref-color-neutral_variant-95: hsl(198deg 42% 94%);
$ref-color-neutral_variant-98: hsl(195deg 86% 97%);
$ref-color-neutral_variant-99: hsl(200deg 100% 99%);
$ref-color-primary-0: hsl(0deg 0% 0%);
$ref-color-primary-10: hsl(226deg 100% 16%);
$ref-color-primary-100: hsl(0deg 0% 100%);
$ref-color-primary-15: hsl(224deg 100% 21%);
$ref-color-primary-20: hsl(223deg 100% 26%);
$ref-color-primary-25: hsl(223deg 100% 31%);
$ref-color-primary-30: hsl(225deg 76% 38%);
$ref-color-primary-35: hsl(227deg 64% 44%);
$ref-color-primary-40: hsl(227deg 56% 49%);
$ref-color-primary-5: hsl(229deg 100% 11%);
$ref-color-primary-50: hsl(228deg 68% 60%);
$ref-color-primary-60: hsl(228deg 93% 70%);
$ref-color-primary-70: hsl(229deg 100% 79%);
$ref-color-primary-80: hsl(230deg 100% 86%);
$ref-color-primary-90: hsl(233deg 100% 93%);
$ref-color-primary-95: hsl(240deg 100% 97%);
$ref-color-primary-98: hsl(266deg 100% 99%);
$ref-color-primary-99: hsl(285deg 100% 99%);
$ref-color-secondary-0: hsl(0deg 0% 0%);
$ref-color-secondary-10: hsl(207deg 100% 10%);
$ref-color-secondary-100: hsl(0deg 0% 100%);
$ref-color-secondary-15: hsl(205deg 100% 13%);
$ref-color-secondary-20: hsl(204deg 100% 17%);
$ref-color-secondary-25: hsl(204deg 100% 20%);
$ref-color-secondary-30: hsl(203deg 100% 24%);
$ref-color-secondary-35: hsl(203deg 100% 27%);
$ref-color-secondary-40: hsl(203deg 100% 31%);
$ref-color-secondary-5: hsl(209deg 100% 7%);
$ref-color-secondary-50: hsl(206deg 64% 45%);
$ref-color-secondary-60: hsl(208deg 65% 57%);
$ref-color-secondary-70: hsl(209deg 88% 68%);
$ref-color-secondary-80: hsl(211deg 100% 80%);
$ref-color-secondary-90: hsl(213deg 100% 91%);
$ref-color-secondary-95: hsl(218deg 100% 96%);
$ref-color-secondary-98: hsl(225deg 100% 98%);
$ref-color-secondary-99: hsl(240deg 100% 99%);
$ref-color-tertiary-0: hsl(0deg 0% 0%);
$ref-color-tertiary-10: hsl(155deg 100% 6%);
$ref-color-tertiary-100: hsl(0deg 0% 100%);
$ref-color-tertiary-15: hsl(158deg 100% 9%);
$ref-color-tertiary-20: hsl(159deg 100% 11%);
$ref-color-tertiary-25: hsl(159deg 100% 14%);
$ref-color-tertiary-30: hsl(160deg 100% 16%);
$ref-color-tertiary-35: hsl(160deg 100% 19%);
$ref-color-tertiary-40: hsl(161deg 100% 21%);
$ref-color-tertiary-5: hsl(151deg 100% 4%);
$ref-color-tertiary-50: hsl(161deg 100% 27%);
$ref-color-tertiary-60: hsl(161deg 100% 32%);
$ref-color-tertiary-70: hsl(158deg 67% 45%);
$ref-color-tertiary-80: hsl(155deg 69% 59%);
$ref-color-tertiary-90: hsl(149deg 79% 79%);
$ref-color-tertiary-95: hsl(147deg 100% 87%);
$ref-color-tertiary-98: hsl(138deg 100% 95%);
$ref-color-tertiary-99: hsl(131deg 100% 98%);

// Sass variables
$sys-color-alternative: var(--sys-color-alternative);
$sys-color-alternative_container: var(--sys-color-alternative_container);
$sys-color-alternative_fixed: var(--sys-color-alternative_fixed);
$sys-color-alternative_fixed_dim: var(--sys-color-alternative_fixed_dim);
$sys-color-background: var(--sys-color-background);
$sys-color-error: var(--sys-color-error);
$sys-color-error_container: var(--sys-color-error_container);
$sys-color-inverse_on_surface: var(--sys-color-inverse_on_surface);
$sys-color-inverse_primary: var(--sys-color-inverse_primary);
$sys-color-inverse_surface: var(--sys-color-inverse_surface);
$sys-color-on_alternative: var(--sys-color-on_alternative);
$sys-color-on_alternative_container: var(--sys-color-on_alternative_container);
$sys-color-on_alternative_fixed: var(--sys-color-on_alternative_fixed);
$sys-color-on_alternative_fixed_variant: var(
  --sys-color-on_alternative_fixed_variant
);
$sys-color-on_background: var(--sys-color-on_background);
$sys-color-on_error: var(--sys-color-on_error);
$sys-color-on_error_container: var(--sys-color-on_error_container);
$sys-color-on_primary: var(--sys-color-on_primary);
$sys-color-on_primary_container: var(--sys-color-on_primary_container);
$sys-color-on_primary_fixed: var(--sys-color-on_primary_fixed);
$sys-color-on_primary_fixed_variant: var(--sys-color-on_primary_fixed_variant);
$sys-color-on_secondary: var(--sys-color-on_secondary);
$sys-color-on_secondary_container: var(--sys-color-on_secondary_container);
$sys-color-on_secondary_fixed: var(--sys-color-on_secondary_fixed);
$sys-color-on_secondary_fixed_variant: var(
  --sys-color-on_secondary_fixed_variant
);
$sys-color-on_surface: var(--sys-color-on_surface);
$sys-color-on_surface_variant: var(--sys-color-on_surface_variant);
$sys-color-on_tertiary: var(--sys-color-on_tertiary);
$sys-color-on_tertiary_container: var(--sys-color-on_tertiary_container);
$sys-color-on_tertiary_fixed: var(--sys-color-on_tertiary_fixed);
$sys-color-on_tertiary_fixed_variant: var(
  --sys-color-on_tertiary_fixed_variant
);
$sys-color-outline: var(--sys-color-outline);
$sys-color-outline_variant: var(--sys-color-outline_variant);
$sys-color-primary: var(--sys-color-primary);
$sys-color-primary_container: var(--sys-color-primary_container);
$sys-color-primary_fixed: var(--sys-color-primary_fixed);
$sys-color-primary_fixed_dim: var(--sys-color-primary_fixed_dim);
$sys-color-scrim: var(--sys-color-scrim);
$sys-color-secondary: var(--sys-color-secondary);
$sys-color-secondary_container: var(--sys-color-secondary_container);
$sys-color-secondary_fixed: var(--sys-color-secondary_fixed);
$sys-color-secondary_fixed_dim: var(--sys-color-secondary_fixed_dim);
$sys-color-shadow: var(--sys-color-shadow);
$sys-color-surface: var(--sys-color-surface);
$sys-color-surface_bright: var(--sys-color-surface_bright);
$sys-color-surface_container: var(--sys-color-surface_container);
$sys-color-surface_container_high: var(--sys-color-surface_container_high);
$sys-color-surface_container_highest: var(
  --sys-color-surface_container_highest
);
$sys-color-surface_container_low: var(--sys-color-surface_container_low);
$sys-color-surface_container_lowest: var(--sys-color-surface_container_lowest);
$sys-color-surface_dim: var(--sys-color-surface_dim);
$sys-color-tertiary: var(--sys-color-tertiary);
$sys-color-tertiary_container: var(--sys-color-tertiary_container);
$sys-color-tertiary_fixed: var(--sys-color-tertiary_fixed);
$sys-color-tertiary_fixed_dim: var(--sys-color-tertiary_fixed_dim);

// to be deleted ↓
$colors-map: (
  "blue-100": #dbeafe,
  "blue-300": #93c5fd,
  "blue-800": #1e40af,

  "white": #fff,

  "gray-50": #f9fafb,
  "gray-100": #f3f4f6,
  "gray-200": #e5e7eb,
  "gray-300": #d1d5db,
  "gray-400": #9ca3af,
  "gray-600": #4b5563,
  "gray-900": #111827,

  "green-600": #059669,
  "green-700": #047857,

  "red-50": #fef2f2,
  "red-200": #fecaca,
  "red-600": #dc2626,
  "red-700": #b91c1c,

  "yellow-200": #fde68a,
  "yellow-700": #b45309,

  "black-transparency-15": #00000026,
  "black-transparency-30": #0000004d,
);

// to be deleted ↑
