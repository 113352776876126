@use "../01-abstracts" as *;

.nax-main {
  display: flex;
  flex-direction: column;
  height: 100dvh;

  & > .nax-main__header {
    display: none;
    grid-area: header;
    height: spacing(13);
    padding-inline: spacing(5);
    background-color: $sys-color-surface_container_lowest;
    border-bottom: spacing(px) solid var(--sys-color-outline_variant);

    @include for-orientation("landscape") {
      display: unset;
      height: spacing(14);
    }

    & > nav {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
      height: 100%;

      & > *:last-child {
        justify-self: end;
      }
    }
  }

  &:has(.nax-home, .nax-changelog) {
    .nax-main__header {
      display: unset;
    }
  }
}
